import axios from 'axios';
import store from '../store';
const prevTOken = localStorage.getItem('token');

const axiosinstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL
});

axiosinstance.interceptors.request.use(function (config) {
    // Do something before request is sent
    const token = localStorage.getItem("token");
    if (token) {
        // config.headers.Authorization = `Bearear ${token}`
        config.headers["x-access-token"] = token;
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

axiosinstance.interceptors.response.use((res) => {
    const token = localStorage.getItem("token");
    if (prevTOken !== token) {
        store.dispatch({ type: "SET_AUTH", payload: {token, user : JSON.parse(localStorage.getItem('user')) }});
    }
    return res;
}, (error) => {
    const status = error.response.status ? error.response.status : 401;
    if (status && status === 401) {
        localStorage.clear('token');
        store.dispatch({
            type: "LOG_OUT"
        })
    }
    return Promise.reject(error);
})

export default axiosinstance;
let initstate  = {
    stats: {},
    userStats: {}
}

export const countReducer  = (state = initstate , action) => {
    const {type , payload} = action;
    switch(type){
        case "SET_ALL_STATS":
            return {...state , stats: payload};
         case "SET_USER_STATS":
            const {weeklyExpiredEnterprises, weeklyPaidUsers, weeklySales, enterprisesAtWeekStart, ...rest} = payload;
            const avgUserRevenue = parseInt(weeklySales) / weeklyPaidUsers;
            rest.avgUserRevenue =  !isNaN(avgUserRevenue) ? avgUserRevenue.toFixed(2) : 0;
            rest.churnRate = (weeklyExpiredEnterprises / enterprisesAtWeekStart * 100).toFixed(2);
            return {...state , userStats: rest};
        default: 
            return state;
    }
}

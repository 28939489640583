import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';


const PrivateRoute = (props) => {
    const { token, user } = useSelector(state => state.authReducer )
    return  (
        token && user ? (
            <Route path={props.path} exact={true} component={props.component} />
        ) : (
            <Redirect to="/auth/signin" />
        )
    ) 
}

export default PrivateRoute;
import axiosinstance from '../../config/axios';
import { toastError, toastsuccess } from '../../handler/index';
import exportfromjson from 'export-from-json';


export const getPlans = ({page, limit}, toast) => async (dispatch) => {
    // const skip = (page -1) * limit;
    try {
        dispatch({ type: "SET_LOADER", payload: true });
        const { data } = await axiosinstance.get(`/admin/plan/list`);
        const totalPages = Math.ceil(data.count / limit);
        const islastpage = page === totalPages;
        const isfirstpage = page === 1;
        dispatch({ type: "SET_PLANS", payload: { plans: data.data , isfirstpage, islastpage } });
        dispatch({ type: "SET_LOADER", payload: false });
        return true;
    }
    catch (error) {
        console.log('Error',error.response.data);
        if (error.response && error.response.data) {
            dispatch({ type: "SET_LOADER", payload: false });
            toastError(error.response.data.error ? error.response.data.error.error : error.response.data.message, toast);
            return false;
        }
    }
}

export const getPlanPagination = ({page, limit}, toast) => async (dispatch) => {
    // const skip = (page -1) * limit;
    try {
        dispatch({ type: "SET_LOADER", payload: true });
        const { data } = await axiosinstance.get(`/admin/plan/list?page=${page-1}&limit=${limit}`);
        const totalPages = Math.ceil(data.count / limit);
        const islastpage = totalPages === 0 ? true : (page === totalPages);
        const isfirstpage = page === 1;
        dispatch({ type: "SET_PLANS", payload: { plans: data.data, isfirstpage, islastpage } });
        dispatch({ type: "SET_LOADER", payload: false });
        return true;
    }
    catch (error) {
        if (error.response && error.response.data) {
            dispatch({ type: "SET_LOADER", payload: false });
            toastError(error.response.data.error ? error.response.data.error.error : error.response.data.message, toast);
            return false;
        }
    }
}

export const searchPlan = ({page, limit, searchText}, toast) => async (dispatch) => {
    // const skip = (page -1) * limit;
    let search = "";
    if(searchText)
        search += `${searchText}`;
    try {
        dispatch({ type: "SET_LOADER", payload: true });
        const { data } = await axiosinstance.get(`/admin/plan/list?page=${page-1}&limit=${limit}&search=${search}`);
        dispatch({ type: "SET_PLANS", payload: { plans: data.data, isfirstpage: true, islastpage: true } });
        dispatch({ type: "SET_LOADER", payload: false });
        return true;
    }
    catch (error) {
        if (error.response && error.response.data) {
            dispatch({ type: "SET_LOADER", payload: false });
            toastError(error.response.data.error ? error.response.data.error.error : error.response.data.message, toast);
            return false;
        }
    }
}

export const downloadPlans = (formdata, toast) => async (dispatch) => {
    try {
        dispatch({ type: "SET_FILE_LOADER", payload: true });
        const { data } = await axiosinstance.get(`/admin/plan/list?start=${formdata.start}&end=${formdata.end}`);
        exportfromjson({ data: data.data, fileName: `remoteeye--${formdata.start}-${formdata.end}`, exportType: formdata.filetype })
        dispatch({ type: "SET_FILE_LOADER", payload: false });
        return true;
    }
    catch (error) {
        if (error.response && error.response.data) {
            dispatch({ type: "SET_FILE_LOADER", payload: false });
            toastError(error.response.data.error ? error.response.data.error.error : error.response.data.message, toast);
            return false;
        }
    }
}

const initialstate = {
    monthlyEnterprise : {xaxies:[] , yaxies: [], count : 0},
    yearlyEnterprise: {xaxies:[] , yaxies: [], count : 0, uptolastyearenterprisecount: 0 },
    daytodayEnterprise :{xaxies:[] , yaxies: [], count : 0} 
}

export const enterprisereportReducer = (state = initialstate , action)=>{
    const { type , payload}  = action;
    switch(type){
        case "SET_MONTHLY_ENTERPRISE_REPORT" :
            return { ...state ,monthlyEnterprise : payload }
        case "SET_YEARLY_ENTERPRISE_REPORT":
             return  { ...state , yearlyEnterprise: payload};
        case "SET_DAY_TO_DAY_ENTERPRISE_REPORT": 
            return {...state , daytodayEnterprise : payload};
        default :
            return state
    }
}

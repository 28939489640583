import axiosinstance from '../../config/axios';
import { toastError, toastsuccess } from '../../handler/index';
import exportfromjson from 'export-from-json';


export const getEnterprises = ({page, limit}, toast) => async (dispatch) => {
    // const skip = (page -1) * limit;
    try {
        dispatch({ type: "SET_LOADER", payload: true });
        const { data } = await axiosinstance.get(`/admin/organization/list?page=${page-1}&limit=${limit}`);
        const totalPages = Math.ceil(data.count / limit);
        const islastpage = page === totalPages;
        const isfirstpage = page === 1;
        dispatch({ type: "SET_ENTERPRISES", payload: { enterprises: data.data , isfirstpage, islastpage } });
        dispatch({ type: "SET_LOADER", payload: false });
        return true;
    }
    catch (error) {
        if (error.response && error.response.data) {
            dispatch({ type: "SET_LOADER", payload: false });
            toastError(error.response.data.message || error.response.data.error, toast);
            return false;
        }
    }
}


export const getEnterprisePagination = ({page, limit, filterBy, start, end}, toast) => async (dispatch) => {
    // const skip = (page -1) * limit;
    let queryParams = `page=${page-1}&limit=${limit}`;
    if(start != undefined && end != undefined)
        queryParams += `&start=${start}&end=${end}`;
    if(filterBy)
        queryParams += `&filterBy=${filterBy}`
    try {
        dispatch({ type: "SET_LOADER", payload: true });
        const { data } = await axiosinstance.get(`/admin/organization/list?${queryParams}`);
        const totalPages = Math.ceil(data.count / limit);
        const islastpage = page === totalPages;
        const isfirstpage = page === 1;
        dispatch({ type: "SET_ENTERPRISES", payload: { enterprises: data.data , isfirstpage, islastpage } });
        dispatch({ type: "SET_LOADER", payload: false });
        return true;
    }
    catch (error) {
        if (error.response && error.response.data) {
            dispatch({ type: "SET_LOADER", payload: false });
            toastError(error.response.data.message || error.response.data.error, toast);
            return false;
        }
    }
}

export const getEnterpriseDetail = (formdata, toast) => async (dispatch) => {
    try {
        dispatch({ type: "SET_LOADER", payload: true });
        const { data } = await axiosinstance.get("", formdata);
        dispatch({ type: "SET_LOADER", payload: false });
        return true;
    }
    catch (error) {
        if (error.response && error.response.data) {
            dispatch({ type: "SET_LOADER", payload: false });
            toastError(error.response.data.message || error.response.data.error, toast);
            return false;
        }
    }
}

export const searchEnterprise = ({page, limit, enterpriseName}, toast) => async (dispatch) => {
    // const skip = (page -1) * limit;
    let searchParams = '';
    if(enterpriseName){
        searchParams = `&search=${enterpriseName}`;
    }
    try {
        dispatch({ type: "SET_LOADER", payload: true });
        const { data } = await axiosinstance.get(`/admin/organization/list?page=${page-1}&limit=${limit}${searchParams}`);
        dispatch({ type: "SET_ENTERPRISES", payload: { enterprises: data.data, isfirstpage: true, islastpage: true } });
        dispatch({ type: "SET_LOADER", payload: false });
        return true;
    }
    catch (error) {
        if (error.response && error.response.data) {
            dispatch({ type: "SET_LOADER", payload: false });
            toastError(error.response.data.message || error.response.data.error, toast);
            return false;
        }
    }
}


export const downloadEnterprises = (formdata, toast) => async (dispatch) => {
    try {
        dispatch({ type: "SET_FILE_LOADER", payload: true });
        const { data } = await axiosinstance.get(`/admin/organization/list?start=${formdata.start}&end=${formdata.end}`);
        exportfromjson({ data: data.data, fileName: `remoteeye-enterprises-${formdata.start}-${formdata.end}`, exportType: formdata.filetype })
        dispatch({ type: "SET_FILE_LOADER", payload: false });
        return true;
    }
    catch (error) {
        if (error.response && error.response.data) {
            dispatch({ type: "SET_FILE_LOADER", payload: false });
            toastError(error.response.data.message || error.response.data.error, toast);
            return false;
        }
    }
}


let init_state  = {
    enterprisePlans : [],
    isfirstpage : false,
    islastpage : false,
    enterprisePlan: {},
    count : 0
}

export const billingReducer  = (state = init_state , action ) => {
    const {type , payload}  = action;
    switch(type){
        case "SET_ENTERPRISE_PLANS":
            return {...state , enterprisePlans : payload.enterprisePlans , isfirstpage : payload.isfirstpage, islastpage : payload.islastpage};
        case "SET_ENTERPRISE_PLAN_DETAILS":
            return {...state, enterprisePlan: payload};
        default :
            return state; 
    }
}

// Chakra imports
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Grid,
  Icon,
  Progress,
  SimpleGrid,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import moment from "moment";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import BarChart from "components/Charts/BarChart";
import LineChart from "components/Charts/LineChart";
import MultiLineChart from "components/Charts/MultiLineChart";
import IconBox from "components/Icons/IconBox";

// Custom icons
import {
  CartIcon,
  DocumentIcon,
  GlobeIcon,
  RocketIcon,
  StatsIcon,
  PersonIcon,
  WalletIcon,
} from "../../components/Icons/Icons";
import DashboardTableRow from "components/Tables/DashboardTableRow";
import TimelineRow from "components/Tables/TimelineRow";
import React, { useState, useEffect } from "react";
// react icons
import { AttachMoney, Assignment, Call, Business, BusinessCenter, LocationOn, TrendingDown } from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
import { BsArrowRight } from "react-icons/bs";
import { IoCheckmarkDoneCircleSharp } from "react-icons/io5";
import { dashboardTableData, timelineData } from "variables/general";

import { useToast } from '@chakra-ui/toast';
import { getAllStats, getUserStats  } from '../../store/actions/user';
// import { getCurruntMonthSaleReports, getYearlySaleReport  } from '../../store/actions/sale-report';
import { getCurruntMonthUserReports , getYearlyUserReport, getActiveUserAvgCount,
getActiveUserCount} from '../../store/actions/user-report';
import { getCurruntMonthEnterpriseReports, getYearlyEnterpriseReport } from '../../store/actions/enterprise-report';

export default function Dashboard() {
  const toast = useToast();
  const today = moment().format("YYYY-MM-DD");
  const start = moment().subtract(1, "month").format("YYYY-MM-DD");
  const [filterData, setfilterData] = useState({
      start: start, end: today
  });
  // Chakra Color Mode
  const { isOpen, onOpen, onClose } = useDisclosure();

  const dispatch = useDispatch();
  const { colorMode, toggleColorMode } = useColorMode();
  const iconTeal = useColorModeValue("orange.200", "orange.200");
  const iconBoxInside = useColorModeValue("white", "white");
  const textColor = useColorModeValue("black", "white");
  let {stats: countdata, userStats} = useSelector(state => state.countReducer);
  countdata = {...countdata, ...userStats}; // merge stats
  const now = new Date();
  const loader = useSelector(state => state.loaderReducer.loader);
  // const salereports = useSelector(state => state.salereportReducer);
  const userreports = useSelector(state => state.userreportReducer);
  const enterprisereports = useSelector(state => state.enterprisereportReducer);


  const overlayRef = React.useRef();

  useEffect(() => {
    dispatch(getAllStats(toast));
    dispatch(getUserStats(toast));
    // dispatch(getCurruntMonthSaleReports({}, toast));
    dispatch(getCurruntMonthUserReports({}, toast))
    dispatch(getCurruntMonthEnterpriseReports({}, toast))
    dispatch(getActiveUserAvgCount({}, toast))// avg count
    dispatch(getActiveUserCount({}, toast))// scheduler count
    // to calculate percentage of extra users are join  in currunt year 
    dispatch(getYearlyUserReport({ startyear: 2021, endyear: now.getFullYear() }, toast));
    // percentage of extra sale in gain in currunt year 
    dispatch(getYearlyEnterpriseReport({ startyear: 2021, endyear: now.getFullYear() }, toast));
  }, []);

  function changeFilter(e) {
      const name = e.target.name;
      setfilterData({ ...filterData, [e.target.name]: e.target.value, ischanged: true });
      if (name == "start" || name == "end") {
          dispatch(getActiveUserCount({
              start: filterData.start,
              end: filterData.end,
              [e.target.name]: e.target.value
          }, toast));
      }
  }

  const {dateRange={}} = countdata;
  if(dateRange.month_start)
    dateRange.month_start = dateRange.month_start.replace("Z", "");
  if(dateRange.endOfDay)
    dateRange.endOfDay = dateRange.endOfDay.replace("Z", "");
  // const month_start_end = `${moment(dateRange.month_start).format("MMM DD")} - ${moment(dateRange.endOfDay).format("MMM DD")}`
  const currentMonth = new Date().toLocaleDateString('default',{month: 'long'});
  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <SimpleGrid columns={{ sm: 2, md: 3, xl: 5 }} spacing="24px">
        {countConfig.map(({label, field, icon}) => {
          const IconComponent = icon;
          return (<Card minH="83px">
          <CardBody>
            <Flex flexDirection="row" align="center" justify="center" w="100%">
              <Stat me="auto">
                <StatLabel
                  fontSize="sm"
                  color={textColor}
                  fontWeight="bold" 
                  pb=".1rem"
                >
                  {label}
                </StatLabel>
                <Flex>
                  <StatNumber fontSize="lg" color={textColor}>

                    {loader ? " Loding..." : countdata[field] >= 0 ? countdata[field] : "NA"}
                  </StatNumber>

                </Flex>
              </Stat>
              <IconBox as="box" h={"45px"} w={"45px"} bg="#f26400">
                <IconComponent h={"24px"} w={"24px"} color={iconBoxInside} style={{ color: "white" }} />
              </IconBox>
            </Flex>
          </CardBody>
        </Card>);
        })}
        
      </SimpleGrid>
      <Grid
        // templateColumns={{ sm: "1fr", lg: "1.5fr 1.5fr" }}
        templateColumns={{ sm: "1fr", lg: "1fr"}}
        templateRows={{ sm: "repeat(2, 1fr)", lg: "1fr" }}
        gap="24px"
        mb={{ lg: "26px" }}
        mt={{ lg: "26px", base: "20px" }}
      >
        <Card p="18px 10px 16px 10px">
          <CardHeader mb="10px" pl="22px">
            <Flex direction="row" alignSelf="flex-start">
              <Text fontSize="lg" color={textColor} fontWeight="bold" mb="6px">
                Active Users
              </Text>
              <Flex ml="20px">
                <Box>
                  <FormControl >
                      <FormLabel htmlFor='start'>From <span>(MM/DD/YYYY)</span></FormLabel>
                      <Input type="date" name="start"  onChange={changeFilter} value={filterData.start} w={"90%"} />
                  </FormControl>
                </Box>
                <Box>
                  <FormControl>
                      <FormLabel htmlFor='end'>To <span>(MM/DD/YYYY)</span></FormLabel>
                      <Input type="date" name="end" onChange={changeFilter} value={filterData.end} w="90%" />
                  </FormControl>
                </Box>
              </Flex>
            </Flex>
          </CardHeader>
          <CardBody>
            <Flex direction="row" w="100%" justifyContent="center">
              <MultiLineChart data={userreports.activeUserCounts} opts={{xaxis: "days", yaxis: ["dailyCounts", "weeklyCounts"]}} 
                title={" " || `Active Users (${currentMonth} - ${new Date().getFullYear()})`} lables={["Daily Total", "Weekly Total"]} width="850" height="320" />
            </Flex>
          </CardBody>
        </Card>
        <Card p="18px 10px 16px 10px">
          <CardHeader mb="10px" pl="22px">
            <Flex direction="row" alignSelf="flex-start">
              <Text fontSize="lg" color={textColor} fontWeight="bold" mb="6px">
                Active Users Report (Average counts)
              </Text>
              <Text fontSize="md" fontWeight="medium" color={textColor}>
                <Text as="span" color="green" fontWeight="bold">
                </Text>{" "}</Text>
            </Flex>
          </CardHeader>
          <CardBody>
            <Flex direction="row" w="100%" justifyContent="center">
              <MultiLineChart data={userreports.activeUsersAvg} opts={{xaxis: "months", yaxis: ["dailyCountAvg", "weeklyCountAvg", "monthlyCount"]}} 
                title={`Active Users Report (${new Date().getFullYear()})`} lables={["Daily Average", "Weekly Average", "Monthly Total"]} width="850" height="320" />
            </Flex>
          </CardBody>
        </Card>
        <Card p="18px 10px 16px 10px">
          <CardHeader mb="10px" pl="22px">
            <Flex direction="row" alignSelf="flex-start">
              <Text fontSize="lg" color={textColor} fontWeight="bold" mb="6px">
                User Report
              </Text>
              <Text fontSize="md" fontWeight="medium" color={textColor}>
                <Text as="span" color="green" fontWeight="bold">
                  (+ { Math.ceil( Math.abs(userreports.yearlyUser.uptolastyearusercount /  userreports.yearlyUser.count)*100)}%) more
                </Text>{" "}
                in {now.getFullYear()}
              </Text>
            </Flex>
          </CardHeader>
          <CardBody>
            <Flex direction="row" w="100%" justifyContent="center">
              <LineChart xaxies={userreports.daytodayUser.xaxies} yaxies={userreports.daytodayUser.yaxies} title={`Current Month User Report (${currentMonth})`} width="850" height="320" />
            </Flex>
          </CardBody>
        </Card>

        <Card p="18px 10px 16px 0px" mb={{ sm: "26px", lg: "0px" }}>
          <CardHeader mb="10px" pl="22px">
            <Flex direction="row" alignSelf="flex-start">
              <Text fontSize="lg" color={textColor} fontWeight="bold" mb="6px">
                Enterprise Report
              </Text>
              <Text fontSize="md" fontWeight="medium" color={textColor}>
                <Text as="span" color="green" fontWeight="bold">
                  (+ { Math.ceil(Math.abs(enterprisereports.yearlyEnterprise.uptolastyearenterprisecount /  enterprisereports.yearlyEnterprise.count)*100)}%) more
                </Text>{" "}
                in {now.getFullYear()}
              </Text>
            </Flex>
          </CardHeader>
          <Flex direction="row" w="100%" justifyContent="center">
            <LineChart xaxies={enterprisereports.daytodayEnterprise.xaxies} yaxies={enterprisereports.daytodayEnterprise.yaxies} title={`Enterprise Report (${currentMonth})`} height={"320"} width="850" />
          </Flex>
        </Card>
      </Grid>

    </Flex>
  );
}

const countConfig = [
  {label: "Today's Enterprises", field: "today_enterprises", icon: Business},
  {label: "Today's Users", field: "today_users", icon: PersonIcon},
  {label: "Today's Sales", field: "today_sales", icon: AttachMoney},
  {label: "Daily Active Users", field: "today_active_users", icon: PersonIcon},
  {label: "Weekly Active Users", field: "weekly_active_users", icon: PersonIcon},
  // {label: "Today's Calls", field: "today_calls", icon: Call},
  {label: "Total Enterpries", field: "enterprises", icon: Business},
  {label: "Total Users", field: "users", icon: PersonIcon},
  {label: "Total Calls", field: "calls", icon: Call},
  {label: "Total Sales", field: "sales", icon: AttachMoney},
  {label: "Paid Users", field: "paidUsers", icon: PersonIcon},
  // {label: "Free Users", field: "freeUsers", icon: PersonIcon},
  {label: "Trial Users", field: "trialUsers", icon: PersonIcon},
  {label: "Expired Users", field: "expUsers", icon: PersonIcon},
  {label: "Churn Rate", field: "churnRate", icon: TrendingDown}, // weekly
  {label: "Countries", field: "countries", icon: LocationOn},
  {label: "Avg User Revenue", field: "avgUserRevenue", icon: AttachMoney} // avg revenue per user
];

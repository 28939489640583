import jwt_decode from 'jwt-decode';
import {getToken , getUser} from '../../config/index';


let initState = {
    user : {
        first_name : "",
        last_name: "",
        email :"",
        image :""
    },
    token :null
}


const verifytoken = (token) => {
    try {
        const decodedToken = jwt_decode(token);
        const ExpireIn = new Date(decodedToken.exp * 1000);
        if (new Date() > ExpireIn) {
            localStorage.removeItem('user');
            return null;
        } else {
            return decodedToken;
        }
    } catch (error) {
        return null;
    }
};

const setToken = (token, user)=>{
    if (token && user) {
        const decodetoken = verifytoken(token);
        if (decodetoken) {
            initState.token  = token;
            initState.user = user;
            return;
        }
    }
    return;    
}
let  token  = getToken();
let user  = getUser();


setToken(token, user);

const authReducer  = (state =initState , action)=> {
    const {type , payload} = action;
    switch (type) {
        case "SET_AUTH":
            return { ...state , user: payload.user, token :  payload.token };
        case "UPDATE_USER":
            return  {...state , user :{...state.user , email : payload.email , first_name : payload.first_name , last_name: payload.last_name}};
        case "UPDATE_USER_IMAGE":
            return {...state , user:{...state.user , image : payload.image}};
        case "LOG_OUT":
            return {...state ,user : null , token : null };
        default:
            return state;
    }

    
}

export default  authReducer;

// Chakra Icons
import { BellIcon, SearchIcon } from "@chakra-ui/icons";
// Chakra Imports
import {
  Button,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  ModalContent,
  ModalCloseButton,
  ModalBody,
 ModalFooter,
 Modal,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useDisclosure,
  ModalOverlay,
  ModalHeader
} from "@chakra-ui/react";
// Assets
import avatar1 from "assets/img/avatars/avatar1.png";
import avatar2 from "assets/img/avatars/avatar2.png";
import avatar3 from "assets/img/avatars/avatar3.png";
// Custom Icons
import { ProfileIcon, SettingsIcon } from "components/Icons/Icons";
// Custom Components
import { ItemContent } from "components/Menu/ItemContent";
import { SidebarResponsive } from "components/Sidebar/Sidebar";
import PropTypes from "prop-types";
import React  from "react";
import { NavLink, useHistory } from "react-router-dom";
import {useDispatch} from 'react-redux';
import routes from "routes.js";
import LogoutIcon from '@mui/icons-material/Logout';
import {logout} from '../../store/actions/auth';
import {useToast} from '@chakra-ui/toast';
export default function HeaderLinks(props) {
  const { variant, children, fixed, secondary, onOpen, ...rest } = props;
  const { isOpen: islogout, onOpen: openlogout, onClose: closelogout } = useDisclosure();
  const textColor = useColorModeValue("black", "white");
  // Chakra Color Mode
  const toast = useToast();
  const dispatch  = useDispatch();
  const history  = useHistory();
  
  let mainTeal = useColorModeValue("teal.300", "teal.300");
  let inputBg = useColorModeValue("white", "gray.800");
  let mainText = useColorModeValue("gray.700", "gray.200");
  let navbarIcon = useColorModeValue("gray.500", "gray.200");
  let searchIcon = useColorModeValue("gray.700", "gray.200");
  function handellogout(){
    dispatch(logout(toast, history))
    
  }

  if (secondary) {
    navbarIcon = "white";
    mainText = "white";
  }
  const settingsRef = React.useRef();
  return (
    <Flex
      pe={{ sm: "0px", md: "16px" }}
      w={{ sm: "100%", md: "auto" }}
      alignItems="center"
      flexDirection="row"
    >
     
        <Button
          ms="0px"
          px="0px"
          me={{ sm: "2px", md: "16px" }}
          color={navbarIcon}
          onClick={openlogout}
          variant="transparent-with-icon"
          leftIcon={
              <LogoutIcon color={navbarIcon} w="22px" h="22px" me="0px" />
           
          }
        >
          <Modal isOpen={islogout} color={textColor} onClose={closelogout}>
                                <ModalOverlay />
                                <ModalContent>
                                    <ModalHeader>Log out</ModalHeader>
                                    <ModalCloseButton />
                                    <ModalBody>
                                      <Text>Are you sure you want to logout ?</Text>  
                                    </ModalBody>

                                    <ModalFooter>
                                        <Button
                                            fontSize="15px"
                                            bg="#f26400"
                                            w="100%"
                                            h="45"
                                            mb="20px"
                                            color="white"
                                            mt="20px"
                                            _hover={{
                                                bg: "orange.300",
                                            }}
                                            _active={{
                                                bg: "orange.300",
                                            }}
                                            colorScheme='orange' mr={3} onClick={closelogout}>
                                            Close
                                        </Button>
                                        <Button
                                            type="submit"
                                            fontSize="15px"
                                            bg="#f26400"
                                            w="100%"
                                            h="45"
                                            mb="20px"
                                            color="white"
                                            mt="20px"
                                            onClick={handellogout}
                                            _hover={{
                                                bg: "orange.300",
                                            }}
                                            _active={{
                                                bg: "orange.300",
                                            }}
                                        >
                                            Log out
                                        </Button>

                                    </ModalFooter>
                                </ModalContent>
                            </Modal>
          <Text display={{ sm: "none", md: "flex" }}>Log out</Text>
        </Button>
      <SidebarResponsive
        logoText={props.logoText}
        secondary={props.secondary}
        routes={routes}
        // logo={logo}
        {...rest}
      />
      <SettingsIcon
        cursor="pointer"
        ms={{ base: "16px", xl: "0px" }}
        me="16px"
        ref={settingsRef}
        onClick={props.onOpen}
        color={navbarIcon}
        w="18px"
        h="18px"
      />
      <Menu>
        <MenuButton>
          <BellIcon color={navbarIcon} w="18px" h="18px" />
        </MenuButton>
        <MenuList p="16px 8px">
          <Flex flexDirection="column">
            <MenuItem borderRadius="8px" mb="10px">
              <ItemContent
                time="13 minutes ago"
                info="from Alicia"
                boldInfo="New Message"
                aName="Alicia"
                aSrc={avatar1}
              />
            </MenuItem>
            <MenuItem borderRadius="8px" mb="10px">
              <ItemContent
                time="2 days ago"
                info="by Josh Henry"
                boldInfo="New Album"
                aName="Josh Henry"
                aSrc={avatar2}
              />
            </MenuItem>
            <MenuItem borderRadius="8px">
              <ItemContent
                time="3 days ago"
                info="Payment succesfully completed!"
                boldInfo=""
                aName="Kara"
                aSrc={avatar3}
              />
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};

import React from 'react';
import {
    InputGroup, Input, InputRightElement, Button
} from '@chakra-ui/react';
import {ViewIcon, ViewOffIcon} from '@chakra-ui/icons'
function PasswordInput(props) {
    const [show, setShow] = React.useState(false)
    const handleClick = () => setShow(!show)
  
    return (
      <InputGroup size='md'>
        <Input
          borderRadius="15px"
          // mb="24px"
          fontSize="sm"
          size="lg"
          bg="white"
          focusBorderColor='orange.100'
          name={props.name }
          value={props.value}
          pr='4.5rem'
          type={show ? 'text' : 'password'}
          placeholder={props.placeholder }
          onChange={props.onChange}
        />
        <InputRightElement width='4.5rem'>
          <Button fontSize={"1.5rem"} marginTop={"5px"} size='lg' bg={"transparent"} alignContent="center" onClick={handleClick}>
            {show ? <ViewOffIcon/>: <ViewIcon/>}
          </Button>
        </InputRightElement>
      </InputGroup>
    )
  }

  export default  PasswordInput;

let init_state  = {
    plans : [],
    isfirstpage : false,
    islastpage : false,
    count : 0
}
export const planReducer  = (state = init_state , action ) => {
    const {type , payload}  = action;
    switch(type){
        case "SET_PLANS":
            return {...state , plans : payload.plans , isfirstpage : payload.isfirstpage, islastpage : payload.islastpage};
        default :
            return state; 
    }
}

/*eslint-disable*/
import React from "react";
import { Flex, Link, List, ListItem, Text } from "@chakra-ui/react";
import PropTypes from "prop-types";

export default function Footer(props) {
  const curyear  = new Date().getFullYear();
  // const linkTeal = useColorModeValue("teal.400", "red.200");=
  return (
    <Flex
      flexDirection={{
        base: "column",
        xl: "row",
      }}
      alignItems={{
        base: "center",
        xl: "start",
      }}
      justifyContent="center"
      px="30px"
      pb="20px"
    >
      <Text>All Rights Reserved. &copy; Copyright {curyear}, RemoteEye.ThirdEyeGen.com </Text>
      
    </Flex>
  );
}

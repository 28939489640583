// Chakra imports
import {
    Box,
    Flex,
    Grid,
    Text,
    FormControl,
    Input,
    FormErrorMessage,
    Modal,
    // Avatar,
    // Wrap,
    // WrapItem,
    useDisclosure,
    Spacer,
    Button,
    ModalContent,
    ModalCloseButton,
    ModalBody,
    InputGroup,
    InputLeftElement,
    ModalHeader,
    ModalOverlay,
    ModalFooter,
    FormLabel,
    Tooltip,
    useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import { Table, Thead, Tbody, Tr, Th, Td, chakra } from '@chakra-ui/react'
import Card from "components/Card/Card.js";
import { Search, Block, Delete } from "@material-ui/icons";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useToast } from '@chakra-ui/toast';
import React, { useState, useEffect, useCallback } from "react";
// react icons
import { useDispatch, useSelector } from 'react-redux';
import PasswordInput from '../../components/Password';
import {isEmailValid, isPasswordValid} from '../../util';
// import BlockIcon from '@material-ui/icons/Block';
// import CheckCircleOutlineIcon from '@material-ui/icons/Restore';
import { getAdmins, deleteAdmin, addAdmin, searchAdmin } from '../../store/actions/admin';
// import avatar from '../../assets/img/avatars/avatar.jpg'
export default function Admins() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isdeletemodel, onOpen: opendeletemodel, onClose: closedeletemodel } = useDisclosure();
    const admins = useSelector(state => state.adminReducer);
    const [adminData, setAdminData] = useState({});
    const [errors, setErrors] = useState({});
    const [page, setpage] = useState(1);
    const toast = useToast();
    const textColor = useColorModeValue("black", "white");
    const dispatch = useDispatch();
    const loader = useSelector(state => state.loaderReducer.loader);
    // const [toggleDModel, settoggleDModel] = useState(false);
    const [curid, setcurid] = useState(null);
    useEffect(() => {
        dispatch(getAdmins(1, 10, toast));
    }, []);
    const formFields = [
        "firstName",
        "lastName",
        "email",
        "password",
        "adminKey"
    ];
    // pagination  to get next page
    function getnextpage() {
        dispatch(getAdmins(page + 1, 10, toast));
        setpage(page + 1);
    }
    // pagination to get previous page
    function getprevouspage() {
        dispatch(getAdmins(page - 1, 10, toast));
        setpage(page - 1);
    }

    function changeAdminData(e) {
        const name = e.target.name;
        const value = e.target.value;
        setAdminData({...adminData, [name]: value});
        // validate - text, email, password
        // add or remove error
        const error = getError(name, value);
        setErrors({...errors, [name]: error});
    }

    function getError(name, value="") {
        let error = '';
        if(!value)
            error = "is required!";
        else if(name === "email"){
            error = isEmailValid(value) ? "" : "Please enter a valid email";
        } else if (name === "password") {
            error = isPasswordValid(value) ? "" : "password should be 8 to 16 chars and should include atleast 1 number & 1 special character";
        }
        return error;
    }

    function handelopenDeleteModel(id) {
        opendeletemodel();
        setcurid(id);
    }
    function handelCloseDeleteModel() {
        closedeletemodel();
        setcurid(null);
    }


    async function changeHandler(e) {
        const searchText = e.target.value;
        if (searchText == "") {
            // get first page admins 
            dispatch(getAdmins(1, 10, toast));
        } else {
            // search admin 
            dispatch(searchAdmin(searchText, toast));
        }
    }
    // if the text difference between two clicks are more than  500 then it will execute
    function debounce(fn, d) {
        let timer;
        return function (...args) {
            const context = this;
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                fn.apply(context, args)
            }, d)
        }
    }
    const searchAdmins = useCallback(debounce(changeHandler, 500), []);

    // Add admin sumbit handler 
    const onSubmit = async (e) => {
        e.preventDefault();
        // console.log('adminData',adminData);
        let validInput = true;
        const adminErrors = {};
        formFields.forEach(name => {
            const value = adminData[name];
            const error = getError(name, value);
            if(error){
                adminErrors[name] = error;
                validInput = false;
            }
        });
        setErrors(adminErrors);
        if(!validInput)
            return false;
        const done = await dispatch(addAdmin(adminData, toast));
        if (done) {
            onClose();
            // refresh the list
            dispatch(getAdmins(1, 10, toast));
            // reset the form
            const data = {};
            formFields.forEach(name => {
                data[name] = null;
            });
            setAdminData(data);
        }
    }

    // delete admin
    const deleteAdminHandler = async () => {
        const id = curid;
        const done = await dispatch(deleteAdmin(id, toast));
        if (done) {
            closedeletemodel();
        }
    }

    return (
        <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>


            <Grid
                templateColumns={{ sm: "1fr", lg: "1fr" }}
                templateRows={{ sm: "repeat(2, 1fr)", lg: "1fr" }}
                gap="24px"
                mb={{ lg: "26px" }}
                mt={{ lg: "26px", base: "20px" }}
            >
                <Card>
                    <Flex>
                        <Box p='4'>

                            <Modal isOpen={isdeletemodel} color={textColor} onClose={handelCloseDeleteModel}>
                                <ModalOverlay />
                                <ModalContent>
                                    <ModalHeader>Delete Admin</ModalHeader>
                                    <ModalCloseButton />
                                    <ModalBody>
                                        <Text>please sure want to Delete admin</Text>
                                    </ModalBody>

                                    <ModalFooter>
                                        <Button
                                            fontSize="15px"
                                            w="100%"
                                            h="45"
                                            mt={"20px"}
                                            mb="20px"
                                            varient="outline"
                                            border={"1px solid orage"}
                                            onClick={handelCloseDeleteModel}>
                                            Close
                                        </Button>
                                        <Button
                                            isLoading={loader}
                                            loadingText='Deleting...'
                                            type="submit"
                                            fontSize="15px"
                                            bg="#f26400"
                                            w="100%"
                                            h="45"
                                            mb="20px"
                                            color="white"
                                            onClick={() => deleteAdminHandler(curid)}
                                            mt="20px"
                                            _hover={{
                                                bg: "orange",
                                            }}
                                            _active={{
                                                bg: "orange.200",
                                            }}
                                        >
                                            Delete
                                        </Button>

                                    </ModalFooter>
                                </ModalContent>
                            </Modal>
                            <Text
                                fontSize="3xl"
                                color={textColor}
                                fontWeight="bold"

                            >
                                All Admins
                            </Text>

                        </Box>
                        <Spacer />



                        <Box p='4' bg='white'>
                            <Button onClick={onOpen} fontSize="15px"
                                bg="#f26400"
                                w="100%"
                                h="45"
                                mb="10px"
                                color="white"
                                _hover={{
                                    bg: "orange.200",
                                }}
                                _active={{
                                    bg: "orange.200",
                                }}>Add Admin</Button>

                            <Modal isOpen={isOpen} color={textColor} onClose={onClose}>
                                <ModalOverlay />
                                <ModalContent>
                                    <ModalHeader>Add Admin</ModalHeader>
                                    <ModalCloseButton />
                                    <ModalBody>
                                    {formFields.map(f => {
                                        const title = f.substring(0,1).toUpperCase() + f.split(/(?=[A-Z])/).join(" ").substring(1);
                                        const errTitle = (f === "email" || f === "password") ? (adminData[f] ? "": title) : title;
                                        return (
                                        <FormControl isRequired isInvalid={errors[f]} key={f} mb="15px">
                                            <FormLabel htmlFor={f} ms="4px" fontSize="sm" fontWeight="normal" color={"black"}>
                                                {title}
                                            </FormLabel>
                                            {f === "password" ? 
                                                (<PasswordInput value={adminData.password } onChange={changeAdminData} name="password" placeholder="Enter password "/>) :
                                                (<Input id={f} name={f} value={adminData[f] || ""} onChange={changeAdminData} 
                                                    type={f === "email" ? "email" : "text"}
                                                    borderRadius="15px"
                                                    fontSize="sm"
                                                    size="lg"
                                                    bg="white"
                                                    focusBorderColor='orange.100'
                                                 />)
                                            }
                                            {errors[f] ? (<FormErrorMessage mt="5px">{`${errTitle} ${errors[f]}`}</FormErrorMessage>) : null}
                                        </FormControl>
                                    )})}
                                    </ModalBody>

                                    <ModalFooter>
                                        <Button
                                            fontSize="15px"
                                            mt="20px"
                                            bg={"transparent"}
                                            w="100%"
                                            h="45"
                                            mb="20px"
                                            border={"1px solid orange"}
                                             mr={3} onClick={onClose}>
                                            Close
                                        </Button>
                                        <Button
                                            isLoading={loader}
                                            loadingText='adding...'
                                            type="submit"
                                            fontSize="15px"
                                            bg="#f26400"
                                            w="100%"
                                            h="45"
                                            mb="20px"
                                            color="white"
                                            onClick={onSubmit}
                                            mt="20px"
                                            _hover={{
                                                bg: "orange.200",
                                            }}
                                            _active={{
                                                bg: "orange.200",
                                            }}
                                        >
                                            Add Admin
                                        </Button>

                                    </ModalFooter>
                                </ModalContent>
                            </Modal>
                        </Box>
                    </Flex>
                    <Card w="80%">
                        <Text
                            color={textColor}
                        >
                            Search admin
                        </Text>
                        <InputGroup>
                            <InputLeftElement
                                pointerEvents='none'
                                mb={"60px"}
                                children={<Search color='gray.300' />}
                            />


                            <Input type='text' borderRadius="15px"
                                bg="white"
                                name="text"
                                onChange={searchAdmins}
                                focusBorderColor='orange.100' placeholder='Enter name or email'
                            />
                        </InputGroup>
                    </Card>
                    <Card w="100%">

                        <Table variant='simple' mt={"1"} >
                            <Thead>
                                <Tr style={{ textTransform: "none" }}>
                                    {/*<Th>Role</Th>*/}
                                    <Th>Email</Th>
                                    <Th>First Name </Th>
                                    <Th>Last Name</Th>
                                    <Th>Actions</Th>
                                </Tr>
                            </Thead>
                            <Tbody >
                                {(admins && (admins.rows && (admins.rows.length || admins.rows.length === 0))) ?
                                    admins.rows.map((row, index) => (
                                        <Tr key={row._id}>
                                            {/*<Td>{row.image == null ? <Wrap>
                                                <WrapItem>
                                                    <Avatar name='Dan Abrahmov' src={avatar} />
                                                </WrapItem></Wrap> : null}
                                            </Td>*/}
                                            {/*<Td> {row.role}</Td>*/}
                                            <Td> {row.email}</Td>
                                            <Td> {row.firstName}</Td>
                                            <Td>{row.lastName}</Td>
                                            <Td>
                                                <Box bg='white' style={{paddingLeft: '1rem', fontWeight: 'bold'}}>
                                                    <Tooltip label='Delete Admin' bg='black' style={{ color: "white" }}>
                                                        <Delete onClick={()=>  handelopenDeleteModel(row._id)} fontSize="15px"
                                                            style={{ color: "red", cursor: "pointer" }}
                                                        />
                                                    </Tooltip>
                                                </Box>
                                            </Td>
                                        </Tr>
                                    )) : <div style={{ padding: '80px' }}>
                                        <div style={{ textAlign: 'center' }}>
                                            Loading...
                                        </div>
                                    </div>}

                            </Tbody>

                        </Table>


                    </Card>
                    <Card>
                        <Flex>
                            <Spacer></Spacer>
                            <Flex>
                                <Box m={"20px"} rounded="md">
                                    <Button
                                        fontSize="15px"
                                        bg="#f26400"
                                        w="100%"
                                        h="45"
                                       borderRadius={"5px"}
                                        color="white"
                                        isDisabled={admins.isfirstpage}
                                        onClick={getprevouspage}
                                        _hover={{
                                            bg: "orange",
                                        }}
                                        _active={{
                                            bg: "orange.200",
                                        }}
                                        colorScheme='orange' mr={3} >
                                        <ChevronLeftIcon style={{ color: "white" }}></ChevronLeftIcon>

                                    </Button>


                                </Box>
                                <Box rounded="md" m={"20px"}>
                                    <Button
                                        isDisabled={admins.islastpage}
                                        fontSize="15px"
                                        bg="#f26400"
                                        w="100%"
                                        h="45"
                                        color="white"
                                       borderRadius={"5px"}
                                        onClick={getnextpage}
                                        _hover={{
                                            bg: "orange",
                                        }}
                                        _active={{
                                            bg: "orange.200",
                                        }}
                                        colorScheme='orange' mr={3} >
                                        <ChevronRightIcon style={{ color: "white" }} ></ChevronRightIcon>
                                    </Button>
                                </Box>
                            </Flex>

                        </Flex>
                    </Card>
                </Card>
            </Grid>
        </Flex>
    );
}

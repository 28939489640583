import React, { Component } from "react";
import Chart from "react-apexcharts";
import  donwloadicon from 'assets/img/download.png'
import {Text} from '@chakra-ui/react';


export default function LineChart(props) {
  let options = {
    stroke: {
      width: 1
    },
    noData: {
      text :"No data found"
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dashed',
        type: "horizontal",
        shadeIntensity: 0.5,
        gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 100],
        colorStops: []
      }
    },
    chart: {
      zoom: {
        enabled: false,
        type: 'x',  
        autoScaleYaxis: false,  
        zoomedArea: {
          fill: {
            color: '#90CAF9',
            opacity: 0.4
          },
          stroke: {
            color: '#0D47A1',
            opacity: 0.4,
            width: 1
          }
        }
    },
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true | '<img src="/static/icons/reset.png" width="20">',
          customIcons: []
        },
        export: {
          csv: {
            filename: `remoteeye-report-${new Date().getTime()}`,
            columnDelimiter: ',',
            headerCategory: 'category',
            headerValue: 'value',
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString()
            }
          },
          svg: {
            filename: `remoteeye-report-${new Date().getTime()}`,
          },
          png: {
            filename:`remoteeye-report-${new Date().getTime()}`,
          }
        },
        autoSelected: `remoteeye-report-${new Date().getTime()}`
      },
  },
    xaxis: {
      categories:  props.xaxies
    },
    colors: [ 'orange', '#00FF00'],
    title: {
      text: props.title,
      align: 'center',
      margin: 10,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: '14px',
        fontWeight: 'bold',
        fontFamily: undefined,
        color: '#263238'
      },
    },
    subtitle: {
      text: "",
      align: 'left',
      margin: 10,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize:  '12px',
        fontWeight:  'normal',
        fontFamily:  undefined,
        color:  '#9699a2'
      },
  }
  }
  let series = props.yaxies;

  return (
    <>
          <Chart
            options={options}
            series={series}
            type="line"
            width={props.width}
            height={props.height}
          />
    </>
  );
}



export const formatEnterpriseMonthlydata  = (data)=>{


    let  result  =  {xaxies : [] , yaxies: [], count: 0 };
    let count  =0;
    for(let index in data){
         result.xaxies.push(`${data[index]._id.month}-${data[index]._id.year}`);
         result.yaxies.push(data[index].count);
         count += parseInt(data[index].count);
    
     }  
    return {xaxies:  result.xaxies, yaxies:  [ {name :"User",  data:  result.yaxies}], count : result.count};
}

export const formatEnterpriseYearlyData  = (data)=>{
     let  result  =  {xaxies : [] , yaxies: [] , count :0 };
     let count  =0;
     let uptolastyearenterprisecount  =  0;
     const  curyear  = new Date().getFullYear();
     for(let index in data){
         result.xaxies.push(data[index]._id.year);
         result.yaxies.push(data[index].count);
         if( parseInt(data[index]._id.year)<  curyear){
             uptolastyearenterprisecount +=  parseInt( data[index].count);
         }
         count +=  parseInt(data[index].count );
     }  
    return {xaxies:  result.xaxies, yaxies:  [ {name :"User",  data:  result.yaxies}], count : count, uptolastyearenterprisecount : uptolastyearenterprisecount };
 
}

export const formatEnterpriseDaytodayData  = (data)=>{
    let result = { xaxies: [], yaxies: [] };
    let dsale = 0;
    let psale = 0;
    const curdate = new Date().getDate();
    const curyear = new Date().getFullYear();
    const curmonth = new Date().getMonth() + 1;
    for (let i = 1; i <= curdate; i++) {
        let flag = false;
        for (let index in data) {
            if (data[index]._id.dayOfMonth == i) {
                result.xaxies.push(`${data[index]._id.dayOfMonth}-${data[index]._id.month}-${data[index]._id.year}`);

                result.yaxies.push(data[index].count);
                dsale += parseInt(data[index].count);
                flag = true;
                break;

            }

        }
        if (flag === false) {
            result.xaxies.push(`${i}-${curmonth}-${curyear}`);
            result.yaxies.push(0);

        }

    }

    return { xaxies: result.xaxies, yaxies: [{ name: "Sale", data: result.yaxies }], domain: dsale, premiumdomain: psale, total: dsale + psale };
}


export const toastsuccess  = (message, toast) => {
    toast({
        title: `${message}`,
        position: "top-right",
        isClosable: true,
        status: 'success',
        duration: 3000
      })
    
}

export const toastError = (message, toast) => {
    toast({
        title: `${message}`,
        position: "top-right",
        isClosable: true,
        status: 'error',
        duration: 3000
      })
}

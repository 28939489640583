let init_state  = {
    enterprises : [],
    isfirstpage : false,
    islastpage : false
}
export const enterpriseReducer  = (state = init_state , action )=>{
    const {type , payload}  = action;
    switch(type){
        case "SET_ENTERPRISES":
            return {...state , enterprises : payload.enterprises , isfirstpage : payload.isfirstpage, islastpage : payload.islastpage};
        default :
            return state; 
    }
}
// import
import Dashboard from "views/Dashboard/Dashboard.js";
import Profile from "views/Pages/Profile.js";
import SignIn from "views/Pages/SignIn.js";
// import SignUp from "views/Pages/SignUp.js";
// import Forgot from "views/Pages/forgot";
// import Resetpassword from 'views/Pages/Resetpassword';
// import { signin } from "store/actions/auth";

import Admins from 'views/Pages/Admins';
import Users from "views/Pages/Users";
// import Sales from 'views/Pages/Sales';
import Analytics from "views/Pages/Reports";
import Enterprises from "views/Pages/Enterprises";
import Plans from "views/Pages/Plans";
import Billing from "views/Pages/Billing";
// const Features = Users;
// const Templates = Users;
// const CustomPlans = Users;

import {
  HomeIcon,
  StatsIcon,
  CreditIcon,
  PersonIcon,
  DocumentIcon,
  RocketIcon,
  SupportIcon,
} from "components/Icons/Icons";
import { Business, Assessment, AttachMoney, Receipt, NewReleases, FileCopy, Settings, Schedule, List as ListIcon } from '@material-ui/icons';


var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: <HomeIcon color="inherit" />,
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/Admins",
    name: "Admin",
    icon: <PersonIcon color="inherit" />,
    component: Admins,
    layout: "/admin",
  },
  {
    path: "/enterprises",
    name: "Enterprises",
    icon: <Business color="inherit" />,
    component: Enterprises,
    layout: "/admin",
  },
  
  {// users will be inside enterprises user count btn click redirect to /Users
    path: "/users/:enterpriseId",
    name: "Users",
    icon: <PersonIcon color="inherit" />,
    component: Users,
    layout: "/admin",
  },
  {
    path: "/plans",
    name: "Plans",
    icon: <ListIcon color="inherit" />,
    component: Plans,
    layout: "/admin",
  },
  {
    path: "/billing",
    name: "Billing",
    icon: <AttachMoney color="inherit" />,
    component: Billing,
    layout: "/admin",
  },
   {
    path: "/analytics",
    name: "Analytics",
    icon: <Assessment color="inherit" />,
    component: Analytics,
    layout: "/admin",
  },
  // {
  //   path: "/features",
  //   name: "Features",
  //   icon: <NewReleases color="inherit" />,
  //   component: Features,
  //   layout: "/admin",
  // },
  // {
  //   path: "/templates",
  //   name: "Templates",
  //   icon: <FileCopy color="inherit" />,
  //   component: Templates,
  //   layout: "/admin",
  // },
  // { // duplicate of billing - list of paid enterprises..
  //   path: "/sales",
  //   name: "Sales",
  //   icon: <Settings color="inherit" />,
  //   component: Sales,
  //   layout: "/admin",
  // },
  // { // coming soon
  //   path: "/custom-plans",
  //   name: "Custom Plans",
  //   icon: <Settings color="inherit" />,
  //   component: CustomPlans,
  //   layout: "/admin",
  // },
  

  
  {
    name: "Account",
    category: "account",
    state: "pageCollapse",
    views: [
      {
        path: "/profile",
        name: "Profile",
        icon: <PersonIcon color="inherit" />,
        secondaryNavbar: true,
        component: Profile,
        layout: "/admin",
      },
      // {
      //   path: "/reset-password/:id",
      //   name: "Reset password",
      //   icon: <DocumentIcon color="inherit" />,
      //   component: Resetpassword,
      //   layout: "/auth",
      // },
      // {
      //   path: "/forgot-password",
      //   name: "Forgot Password",
      //   icon: <DocumentIcon color="inherit" />,
      //   component: Forgot,
      //   layout: "/auth",
      // },
      {
        path: "/signin",
        name: "Sign in",
        icon: <DocumentIcon color="inherit" />,
        component: SignIn,
        layout: "/auth",
      },
      
      // {
      //   path: "/signup/:token",
      //   name: "Sign up",
      //   icon: <DocumentIcon color="inherit" />,
      //   component: SignUp,
      //   layout: "/auth",
      // }
    ],
  },
];
export default dashRoutes;

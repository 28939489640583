import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import allReducer from './reducers/index';

const middleware = [thunkMiddleware];

const Store = createStore(
    allReducer,
    process.env.REACT_APP_ENVIROMENT === "development" ? composeWithDevTools(applyMiddleware(...middleware)) : applyMiddleware(...middleware)
);

export default Store;
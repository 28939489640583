import { combineReducers } from 'redux';
import authReducer from './auth';
import loaderReducer  from './loader';
import {imageloaderReducer}  from './imageloader';
import {adminReducer} from './admin';
import {userReducer} from './user';
import {planReducer} from './plan';
import {billingReducer} from './billing';
import {fileloaderReducer} from './file-loader';
import {enterpriseReducer} from './enterprise';
import {countReducer} from  './count';
import {salereportReducer} from './sale-report';
import {userreportReducer} from  './user-report';
import {enterprisereportReducer} from  './enterprise-report';

const rootReducer = combineReducers({
    authReducer ,
    loaderReducer,
    imageloaderReducer,
    adminReducer,
    userReducer,
    planReducer,
    billingReducer,
    fileloaderReducer,
    enterpriseReducer,
    countReducer ,
    userreportReducer ,
    enterprisereportReducer,
    salereportReducer
});

export default rootReducer;

import React from 'react';
import { Button} from '@chakra-ui/react';
import {DownloadIcon} from '@chakra-ui/icons';

const DownloadButton = ({handeldownload, fileloader })=>{
    return (
        <React.Fragment>
            <Button
                isLoading={fileloader}
                loadingText='downloading...'
                type="submit"
                leftIcon={<DownloadIcon color={"white"} />}
                fontSize="15px"
                bg="#f26400"
                w="100%"
                borderRadius={"5px"}
                onClick={handeldownload}
                h="45"
                mb="20px"
                color="white"
                mt="30px"
                _hover={{
                    bg: "orange",
                }}
                _active={{
                    bg: "orange.200",
                }}
            >
                Download
            </Button>
        </React.Fragment>
        
    )
}

export default DownloadButton;

const loaderReducer  = (state={loader : false} , action )=>{
    const {type , payload}  = action;
    switch (type) {
        case "SET_LOADER":
            return {...state , loader: payload};
        default:
            return {...state , loader :false};
    }
}

export default loaderReducer;
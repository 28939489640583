// Chakra Imports
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  useDisclosure,
  Flex,
  Icon,
  Link,
  Switch,
  Text,
  FormControl,
  FormLabel,
  Input,
  IconButton,
  useColorMode,
  useColorModeValue,
  Wrap,
  DrawerOverlay,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  WrapItem,
  Avatar
} from "@chakra-ui/react";
import GitHubButton from "react-github-btn";
import { Separator } from "components/Separator/Separator";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { FaPen } from "react-icons/fa";
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { toastError } from "../../handler/index";
import { reset_password_auth, updateData, updateprofileImage, logout } from '../../store/actions/auth';
import { useToast } from '@chakra-ui/toast';
import loadingsvg from '../../assets/svg/loading.svg';
import PasswordInput from '../../components/Password';

import avatar from '../../assets/img/avatars/avatar.jpg';
export default function Configurator(props) {
  const { secondary, isOpen, onClose, fixed, ...rest } = props;
  
  const [switched, setSwitched] = useState(props.isChecked);
  const textColor = useColorModeValue("black", "white");
  const toast = useToast();
  const user = useSelector(state => state.authReducer.user);
  const history = useHistory();

  const dispatch = useDispatch();
  const imageloader = useSelector(state => state.imageloaderReducer.loader);
  const [data, setdata] = useState({ email: user.email, firstName: user.firstName, lastName: user.lastName, image: user.image || null });

  const { colorMode, toggleColorMode } = useColorMode();
  const [editdata, seteditdata] = useState(false);
  const [editimage, seteditimage] = useState(false);
  const { isOpen: isreset, onOpen: resetopen, onClose: closereset } = useDisclosure();
  const loader = useSelector(state => state.loaderReducer.loader);

  const [resetdata, setresetdata] = useState({ password: "", repeat_password: "" });

  const handleUpload = async (event) => {
    let file = event.target.files[0];
    let types = /(\.|\/)(jpg|png|jpeg)$/i;
    if (file && file.name && !types.test(file.type)) {
      toastError("Please Select jpg , png, jpeg", toast);
      dispatch({ type: "SET_IMAGE_LOADER", payload: false })
      return
    }
    let formData = new FormData();
    formData.append('file', file);
    const resp = await dispatch(updateprofileImage(formData, toast));
    if (resp) {
      setdata({ ...data, image: `${process.env.REACT_APP_API_BASE_URL}/images/${resp}` });
    }

  }
  // change handler 
  const changeResetdata = (e) => {
    setresetdata({ ...resetdata, [e.target.name]: e.target.value });
  }
  const changeUserdata = (e) => {
    setdata({ ...data, [e.target.name]: e.target.value });
  }

  async function handelReset(e) {
    e.preventDefault();
    if (resetdata.password === "" || resetdata.repeat_password === "") {
      toastError("Password or Repeat Password Required", toast);
    }
    else if (resetdata.password != resetdata.repeat_password) {
      toastError("Password and Repeat Password does not match", toast);
    }
    else {
      const done = await dispatch(reset_password_auth(resetdata, toast));
      if (done) {
        closereset();
           
      }
    }
  }

  async function handelUpdateData() {
    const isdone = await dispatch(updateData({ first_name: data.firstName, last_name: data.lastName, email: data.email }, toast));
    if (isdone) seteditdata(false);
  }

  // Chakra Color Mode
  let fixedDisplay = "flex";
  if (props.secondary) {
    fixedDisplay = "none";
  }

  let bgButton = useColorModeValue(
    "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
    "white"
  );

  let colorButton = useColorModeValue("white", "gray.700");
  const secondaryButtonBg = useColorModeValue("white", "transparent");
  const secondaryButtonBorder = useColorModeValue("gray.700", "white");
  const secondaryButtonColor = useColorModeValue("gray.700", "white");
  const settingsRef = React.useRef();
  return (
    <>
      <Drawer
        isOpen={props.isOpen}
        onClose={props.onClose}
        placement={document.documentElement.dir === "rtl" ? "left" : "right"}
        finalFocusRef={settingsRef}
        blockScrollOnMount={false}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody mt={"30px"} w="340px" ps="24px" pe="40px">
            <Flex justify="center" align="center">
              <Wrap>
                <WrapItem>
                  <input
                    id='profile-upload-input'
                    name="file"
                    type='file'
                    accept='.png,.jpg,.svg'
                    onChange={handleUpload}
                    hidden
                  />
                  <Avatar size='2xl' cursor={"pointer"} name='my avatar' src={imageloader ? loadingsvg : user.image ? `${process.env.REACT_APP_API_BASE_URL}/images/${data.image}` : avatar}
                    onClick={() => {
                      return false;
                      // document
                      //   .getElementById('profile-upload-input')
                      //   .click()
                    }} />
                </WrapItem>
              </Wrap>

              {/*<IconButton
                mt={"-35%"}
                ml="-10%"
                onClick={() =>
                  document
                    .getElementById('profile-upload-input')
                    .click()
                }
                isLoading={imageloader}
                variant='unStyled'
                color="orange.200"
                aria-label='Call Sage'
                fontSize='20px'
                icon={<FaPen />}
              />*/}
            </Flex>

            <Flex justify={"center"} align="center">
              {editdata == false ? 
                <Box mt="10px">
                  <Text fontSize='3x1'>{data.email}</Text>
                  <Text fontSize='3x1'>{data.firstName}   {data.lastName}</Text>
                  <Text fontSize='3x1'></Text> </Box>
                : <FormControl>
                  <FormLabel htmlFor='email'>Email address</FormLabel>
                  <Input id='email' type='email' isRequired={true} name="email" value={data.email} onChange={changeUserdata} />
                  <FormLabel htmlFor="first_name">
                    First Name
                  </FormLabel>
                  <Input id="first_name" name="first_name" value={data.firstName} onChange={changeUserdata} type="text" />
                  <FormLabel htmlFor="last_name">
                    Last Name
                  </FormLabel>
                  <Input id="last_name" name="last_name" value={data.lastName} onChange={changeUserdata} type="text" />
                  <Button
                    isLoading={loader}
                    loadingText='updating...'
                    type="submit"
                    fontSize="15px"
                    bg="orange.200"
                    w="100%"
                    h="45"
                    mb="20px"
                    color="white"
                    onClick={handelUpdateData}
                    mt="20px"
                    _hover={{
                      bg: "orange",
                    }}
                    _active={{
                      bg: "orange",
                    }}
                  >
                    Update
                  </Button>
                </FormControl>}


            </Flex>
            {/*<Flex>
              {editdata == false ? <Button
                fontSize="15px"
                bg="orange.200"
                w="100%"
                h="45"
                mb="20px"
                color="white"
                onClick={() => seteditdata(true)}
                mt="20px"
                _hover={{
                  bg: "orange",
                }}
                _active={{
                  bg: "orange",
                }}
              >
                Edit
              </Button> : null}


            </Flex>
            <Flex>
              {editdata == false ? <Box w="100%">
                <Button onClick={resetopen} fontSize="15px"
                  bg="orange.200"
                  w="100%"
                  h="45"
                  mb="10px"
                  color="white"
                  _hover={{
                    bg: "orange",
                  }}
                  _active={{
                    bg: "orange",
                  }}>Reset Password</Button>

                <Modal isOpen={isreset} color={textColor} onClose={closereset}>
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader>Reset Password</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                      <FormControl>
                        <FormLabel ms="4px" fontSize="sm" fontWeight="normal" color={"black"}>
                          Enter Password  <span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <PasswordInput value={resetdata.password } onChange={changeResetdata} name="password" placeholder="Enter password "/>


                        <FormLabel ms="4px" fontSize="sm" fontWeight="normal" color={"black"}>
                          Enter Repeat Password  <span style={{ color: "red" }}>*</span>
                        </FormLabel>
                       
                        <PasswordInput value={resetdata.repeat_password } onChange={changeResetdata} name="repeat_password" placeholder="Enter repeat password "/>


                        <ModalFooter>
                          <Button
                            fontSize="15px"
                            bg="orange.200"
                            w="100%"
                            h="45"
                            mb="20px"
                            color="white"
                            mt="20px"
                            _hover={{
                              bg: "orange",
                            }}
                            _active={{
                              bg: "orange",
                            }}
                            colorScheme='orange' mr={3} onClick={closereset}>
                            Close
                          </Button>
                          <Button
                            isLoading={loader}
                            loadingText='reseting...'
                            fontSize="15px"
                            bg="orange.200"
                            w="100%"
                            h="45"
                            mb="20px"
                            color="white"
                            onClick={handelReset}
                            mt="20px"
                            _hover={{
                              bg: "orange",
                            }}
                            _active={{
                              bg: "orange",
                            }}
                          >
                            Reset
                          </Button>

                        </ModalFooter>


                      </FormControl>
                    </ModalBody>

                  </ModalContent>
                </Modal>

              </Box> : null}
            </Flex>*/}
            <Flex>
              {editdata ? <Button
                fontSize="15px"
                bg="orange.200"
                w="100%"
                h="45"
                mb="20px"
                color="white"
                onClick={() => seteditdata(false)}
                mt="20px"
                _hover={{
                  bg: "orange",
                }}
                _active={{
                  bg: "orange",
                }}
              >
                Back
              </Button> : null}


            </Flex>
            {editdata == false ? <Box>
              <Flex justify={"center"} pt="24px" px="24px" align="center">

                <Text fontSize="xl" textAlign={"center"} fontWeight="bold" mt="16px" mb={"16px"}>
                  Confifure Dashboard
                </Text>

              </Flex>
              <Separator />
              <Flex flexDirection="column">

                <Box
                  display={fixedDisplay}
                  justifyContent="space-between "
                  mb="16px"
                >
                  <Text fontSize="md" fontWeight="600" mb="4px">
                    Navbar Fixed
                  </Text>
                  <Switch
                    colorScheme="teal"
                    isChecked={switched}
                    onChange={(event) => {
                      if (switched === true) {
                        props.onSwitch(false);
                        setSwitched(false);
                      } else {
                        props.onSwitch(true);
                        setSwitched(true);
                      }
                    }}
                  />
                </Box>
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  mb="24px"
                >
                  <Text fontSize="md" fontWeight="600" mb="4px">
                    Dark/Light
                  </Text>
                  <Button onClick={toggleColorMode}>
                    Toggle {colorMode === "light" ? "Dark" : "Light"}
                  </Button>
                </Flex>

                <Separator />

              </Flex></Box> : null}

          </DrawerBody>
        </DrawerContent>
      </Drawer >
    </>
  );
}
Configurator.propTypes = {
  secondary: PropTypes.bool,
  isOpen: PropTypes.func,
  onClose: PropTypes.func,
  fixed: PropTypes.bool,
};

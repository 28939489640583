import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import PasswordInput from '../../components/Password';

// Chakra imports
import {
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import { signin } from '../../store/actions/auth';
import { toastError, toastsuccess } from '../../handler/index';
import { useToast } from '@chakra-ui/toast';
function SignIn() {
  // Chakra color mode
  const titleColor = useColorModeValue("black", "teal.200");
  const textColor = useColorModeValue("black", "white");
  const loader = useSelector(state => state.loaderReducer.loader);
  const dispatch = useDispatch();
  const [data, setData] = useState({ email: "", password: "" })
  const toast = useToast();
  const history = useHistory();
  const handelchange = (event) => {
    const { name, value } = event.target;
    setData((preVal) => {
      return {
        ...preVal,
        [name]: value,
      };
    });
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    
    if (data.email=="" || data.password=="") {
      toastError("Email and password must be provided", toast);
    } else {
     const done = await  dispatch(signin(data, toast));
     if(done){
       history.push("/admin/dashboard");
     }
     


    }
  }
  return (
    <Flex position="relative" mb="40px">
      <Flex
        h={{ sm: "initial", md: "75vh", lg: "85vh" }}
        w="100%"
        maxW="1044px"
        mx="auto"
        justifyContent="space-between"
        mb="30px"
        mt="30px"
        pt={{ sm: "100px", md: "0px" }}
      >
        <Flex
          alignItems="center"
          justifyContent="start"
          style={{ userSelect: "none" }}
          mx="auto"
          mt={{ md: "150px", lg: "60px" }}
          w={{ base: "90%", md: "50%", lg: "42%" }}
        >
          <Flex
            direction="column"
            w="100%"
            background="white"
            p="48px"
            marginTop={"100px"}
            mt={{ md: "150px", lg: "70px" }}
          >
            <Heading color={titleColor} textAlign="center" fontSize="32px" mb="20px">
              Welcome Back
            </Heading>
            <FormControl onSubmit={onSubmit}>
              <FormLabel ms="4px" fontSize="sm" fontWeight="normal" color={"black"}>
                Email <span style={{color:"red"}}>*</span>
              </FormLabel>
              <Input
                isRequired
                borderRadius="15px"
                mb="24px"
                name="email"
                value={data.email}
                fontSize="sm"
                type="email"
                onChange={handelchange}
                placeholder="test@gmail.com"
                size="lg"
                bg="white"
                focusBorderColor='orange.100'

              />
              <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                Password  <span style={{color:"red"}}>*</span>
              </FormLabel>
              
               <PasswordInput onChange={handelchange}
                name="password"
                value={data.password}
               
               placeholder={"abcd@1234"}
                />

              <Button
                isLoading={loader}
                loadingText='Submitting'
                type="submit"
                fontSize="15px"
                bg="#f26400"
                w="100%"
                h="45"
                mb="20px"
                color="white"
                onClick={onSubmit}
                mt="20px"
                _hover={{
                  bg: "orange",
                }}
                _active={{
                  bg: "#f26400",
                }}
              >
                Sign in
              </Button>


            </FormControl>
            {/*<Flex
              flexDirection="column"
              justifyContent="flex-end"
              alignItems="flex-end"
              maxW="100%"
              mt="0px"
            >
              <Text color={textColor} >
                <Link to="/auth/forgot-password" color={"orange"} textTransform="none" as="span" ms="5px" >
                  forgot password
                </Link>
              </Text>
            </Flex>*/}
          </Flex>
        </Flex>

      </Flex>
    </Flex>
  );
}

export default SignIn;

import axiosinstance from '../../config/axios';
import { toastsuccess, toastError } from '../../handler/index';
import {updatelocalUser, changelocalImage} from '../../config/index';
export const signin = (formdata, toast) => async (dispatch) => {
    try {
        dispatch({ type: "SET_LOADER", payload: true });
        const { data } = await axiosinstance.post('/admin/login', formdata);
        localStorage.setItem('token', data.result.token);
        localStorage.setItem('user', JSON.stringify(data.result));
        dispatch({ type: "SET_LOADER", payload: false });
        dispatch({ type: "SET_AUTH", payload: { token: data.result.token, user: data.result } });
        toastsuccess("Sign successfull", toast);
        return true;
    }
    catch (error) {
        if (error.response && error.response.data) {
            dispatch({ type: "SET_LOADER", payload: false });
                
                toastError(error.response.data.error, toast);
                return false;

        }
    }

}



export const forgot_password = (formdata, toast) => async (dispatch) => {
    try {
        dispatch({ type: "SET_LOADER", payload: true });
        const { data } = await axiosinstance.post('/auth/forget', formdata);
        dispatch({ type: "SET_LOADER", payload: false });
        toastsuccess("Reset Link send to your email please check", toast);
        return true;
    }
    catch (error) {
        if (error.response && error.response.data) {           
            dispatch({ type: "SET_LOADER", payload: false });
            toastError(error.response.data.message, toast);
            return false;

        }
    }

}



export const reset_password = (id,formdata, toast) => async (dispatch) => {
    try {
        dispatch({ type: "SET_LOADER", payload: true });
        const { data } = await axiosinstance.post(`/auth/reset-password/${id}`, formdata);
        dispatch({ type: "SET_LOADER", payload: false });
        toastsuccess("Password changed successfully", toast);
        return true;
    }
    catch (error) {
        if (error.response && error.response.data) {
            dispatch({ type: "SET_LOADER", payload: false });
                toastError(error.response.data.message, toast);
                return false;

        }
    }

}
export const reset_password_auth=  (formdata , toast)=> async (dispatch)=>{
    try {
        dispatch({ type: "SET_LOADER", payload: true });
        const { data } = await axiosinstance.post(`/auth/reset-password`, formdata);
        dispatch({ type: "SET_LOADER", payload: false });
        toastsuccess("Password changed successfully", toast);
        return true;
    }
    catch (error) {
        if (error.response && error.response.data) {
            dispatch({ type: "SET_LOADER", payload: false });
                toastError(error.response.data.message, toast);
                return false;

        }
    }
}



export const  updateData  = (formdata , toast) => async (dispatch)=>{
    try{
        // update admin data
        dispatch({ type :  "SET_LOADER", payload : true});
        const {data} = await axiosinstance.put(`/admin/profile`, formdata);
        // update user is local
        updatelocalUser(formdata);
        // update user in state
        dispatch({ type :"UPDATE_USER" , payload : formdata});

        dispatch({ type: "SET_LOADER", payload : false});
 toastsuccess("Profile updated successfully", toast);
    }
    catch(error){
        dispatch({ type: "SET_LOADER", payload: false });
        toastError(error.response.data.message, toast);
        return false;
    }
}

export const updateprofileImage =  (formdata, toast)=>  async (dispatch)=>{
    try{
        dispatch({ type : "SET_IMAGE_LOADER", payload : true});
        const {data}  = await axiosinstance.put('/admin/profile-image', formdata);
        changelocalImage(data.data);
        dispatch({type : "SET_IMAGE_LOADER" , payload: false});
        dispatch({type :"UPDATE_USER_IMAGE",  payload: data.data});
        
        toastsuccess("Porfile image updated successfully", toast);
        return data.data.image;

        
    }
    catch(error){
        dispatch({ type: "SET_IMAGE_LOADER", payload: false });
        toastError(error.response.data.message, toast);
        return false;
    }
}

export const logout = (toast, history)=> async (dispatch)=>{
    try{
        localStorage.clear();
        dispatch({type :"LOG_OUT"});
        
        history.push("/auth/signin");
        toastsuccess("Log out successfully ", toast);
        return true;

    }
    catch(error){
        dispatch({ type: "SET_IMAGE_LOADER", payload: false });
        toastError(error.response.data.message, toast);
        return false;
    }
}
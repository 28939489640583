

export const fileloaderReducer  = (state = {loader: false} , action)=>{
    const { type, payload}  = action;
    switch(type){
        case "SET_FILE_LOADER":
            return {...state , loader : payload};
        default:
            return state;

    }
}
const initialstate = {
    totalUser :0,
    monthlyUser : {xaxies:[] , yaxies: [], count : 0},
    yearlyUser: {xaxies:[] , yaxies: [], count : 0, uptolastyearusercount: 0 },
    daytodayUser: {xaxies:[] , yaxies: [], count : 0},
    activeUsersAvg: {dailyCountAvg:[], weeklyCountAvg: [], monthlyCount: [], months: []},
    activeUserCounts: {dailyCounts:[], weeklyCounts: [], monthlyCounts: [], days: []},
}

export const userreportReducer = (state = initialstate , action)=> {
    const { type , payload}  = action;
    switch(type){
        case "SET_TOTAL_USER": 
            return { ...state , totalUser :  payload};
        case "SET_MONTHLY_USER_REPORT" :
            return { ...state ,monthlyUser : payload }
        case "SET_YEARLY_USER_REPORT":
             return  { ...state , yearlyUser: payload};
        case "SET_DAY_TO_DAY_USER_REPORT": 
            return {...state , daytodayUser : payload};
        case "SET_ACTIVE_USER_AVG_COUNT":
            return {...state , activeUsersAvg: payload};
        case "SET_ACTIVE_USER_COUNTS":
            return {...state , activeUserCounts: payload};
        default :
            return state
    }
}

const initialstate = {
    loader : false
}

export const imageloaderReducer  = (state = initialstate , action)=>{
    const { type , payload} = action;
    switch (type) {
        case "SET_IMAGE_LOADER":
            return {...state , loader :payload};
        default:
            return state;
    }
}


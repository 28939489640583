import axiosinstance from '../../config/axios';
import { toastError, toastsuccess } from '../../handler/index';
import exportfromjson from 'export-from-json';


export const getUsers = ({page, limit}, toast) => async (dispatch) => {
    // const skip = (page -1) * limit;
    try {
        dispatch({ type: "SET_LOADER", payload: true });
        const { data } = await axiosinstance.get(`/admin/user/list?page=${page-1}&limit=${limit}`);
        const totalPages = Math.ceil(data.count / limit);
        const islastpage = page === totalPages;
        const isfirstpage = page === 1;
        dispatch({ type: "SET_USERS", payload: { users: data.data , isfirstpage, islastpage } });
        dispatch({ type: "SET_LOADER", payload: false });
        return true;
    }
    catch (error) {
        if (error.response && error.response.data) {
            dispatch({ type: "SET_LOADER", payload: false });
            toastError(error.response.data.message || error.response.data.error, toast);
            return false;
        }
    }
}


export const getUserPagination = ({page, limit, enterpriseId}, toast) => async (dispatch) => {
    // const skip = (page -1) * limit;
    const EntId = enterpriseId ? `&enterpriseId=${enterpriseId}` : "";
    try {
        dispatch({ type: "SET_LOADER", payload: true });
        const { data } = await axiosinstance.get(`/admin/user/list?page=${page-1}&limit=${limit}&${EntId}`);
        const totalPages = Math.ceil(data.count / limit);
        const islastpage = page === totalPages;
        const isfirstpage = page === 1;
        dispatch({ type: "SET_USERS", payload: { users: data.data , isfirstpage, islastpage } });
        dispatch({ type: "SET_LOADER", payload: false });
        return true;
    }
    catch (error) {
        if (error.response && error.response.data) {
            dispatch({ type: "SET_LOADER", payload: false });
            toastError(error.response.data.message || error.response.data.error, toast);
            return false;
        }
    }
}

export const getUserDetail = (formdata, toast) => async (dispatch) => {
    try {
        dispatch({ type: "SET_LOADER", payload: true });
        const { data } = await axiosinstance.get("", formdata);
        dispatch({ type: "SET_LOADER", payload: false });
        return true;
    }
    catch (error) {
        if (error.response && error.response.data) {
            dispatch({ type: "SET_LOADER", payload: false });
            toastError(error.response.data.message, toast);
            return false;
        }
    }
}

export const searchUser = ({page, limit, searchText, enterpriseId}, toast) => async (dispatch) => {
    // const skip = (page -1) * limit;
    let search = "";
    let EntId = "";
    if(searchText)
        search = `&search=${searchText}`;
    if(enterpriseId)
        EntId = `&enterpriseId=${enterpriseId}`;
    try {
        dispatch({ type: "SET_LOADER", payload: true });
        const { data } = await axiosinstance.get(`/admin/user/list?page=${page-1}&limit=${limit}${EntId}${search}`);
        dispatch({ type: "SET_USERS", payload: { users: data.data, isfirstpage: true, islastpage: true } });
        dispatch({ type: "SET_LOADER", payload: false });
        return true;
    } catch (error) {
        if (error.response && error.response.data) {
            dispatch({ type: "SET_LOADER", payload: false });
            toastError(error.response.data.message, toast);
            return false;
        }
    }
}


export const downloadUsers = (formdata, toast) => async (dispatch) => {
    try {
        dispatch({ type: "SET_FILE_LOADER", payload: true });
        const { data } = await axiosinstance.get(`/admin/user/list?start=${formdata.start}&end=${formdata.end}`);
        exportfromjson({ data: data.data, fileName: `remoteeye--${formdata.start}-${formdata.end}`, exportType: formdata.filetype })
        dispatch({ type: "SET_FILE_LOADER", payload: false });
        return true;
    }
    catch (error) {
        if (error.response && error.response.data) {
            dispatch({ type: "SET_FILE_LOADER", payload: false });
            toastError(error.response.data.message, toast);
            return false;
        }
    }
}


export const getAllStats = (toast) => async (dispatch) => {
    try {
        //  get the count 
        dispatch({ type: "SET_LOADER", payload: true });
        const { data } = await axiosinstance.get(`/admin/stats?entities=users,enterprises,calls,sales,active_users&options=today`);
        dispatch({ type: "SET_ALL_STATS", payload: data.data });
        dispatch({ type: "SET_LOADER", payload: false });
        return true;
    }
    catch (error) {
        if (error.response && error.response.data) {
            dispatch({ type: "SET_LOADER", payload: false });
            toastError(error.response.data.message, toast);
            return false;
        }
    }

}

export const getUserStats = (toast) => async (dispatch) => {
    try {
        dispatch({ type: "SET_LOADER", payload: true });
        const { data } = await axiosinstance.get(`/admin/user-stats`); // ?end_date=2022-09-30T23:59:59
        dispatch({ type: "SET_USER_STATS", payload: data.data && data.data.length === 1 ? data.data[0] : data.data});
        dispatch({ type: "SET_LOADER", payload: false });
        return true;
    }
    catch (error) {
        if (error.response && error.response.data) {
            dispatch({ type: "SET_LOADER", payload: false });
            toastError(error.response.data.message, toast);
            return false;
        }
    }

}

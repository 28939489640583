import axiosinstance from '../../config/axios';
import { toastError, toastsuccess } from '../../handler/index';


export const addAdmin = (data, toast) => async (dispatch) => {
    try {
        dispatch({ type: "SET_LOADER", payload: true });
        const response = await axiosinstance.post("/admin/create", data);
        dispatch({ type: "SET_LOADER", payload: false });
        toastsuccess("Invitation Sent Successfully", toast);
        dispatch(getAdmins)
        return true;
    }
    catch (error) {
        if (error.response && error.response.data) {
            dispatch({ type: "SET_LOADER", payload: false });
            toastError(error.response.data.message || error.response.data.error, toast);
            return false;
        }
    }
}


export const getAdmins = (page, limit, toast) => async (dispatch) => {
    // const skip = (page -1) * limit;
    try {
        dispatch({ type: "SET_LOADER", payload: true });
        const { data } = await axiosinstance.get(`/admin/superuser/list?page=${page-1}&limit=${limit}`);
        const totalPages = Math.ceil(data.count / limit);
        const islastpage = page === totalPages;
        const isfirstpage = page === 1;
        const result = {rows: data.data, count: data.count, page: page, islastpage: islastpage, isfirstpage: isfirstpage };
        dispatch({type :"SET_ADMINS", payload: {...result}})
        dispatch({ type: "SET_LOADER", payload: false });
        return true;
    }
    catch (error) {
        if (error.response && error.response.data) {
            dispatch({ type: "SET_LOADER", payload: false });

            toastError(error.response.data.message || error.response.data.error, toast);
            return false;

        }
    }
}

export const deleteAdmin = (id, toast) => async (dispatch) => {
    try {
        dispatch({ type: "SET_LOADER", payload: true });
        const { data } = await axiosinstance.post(`/admin/superuser/delete`, {ids: [id]});
        dispatch({ type: "DELETE_ADMIN", payload: { id } });
        dispatch({ type: "SET_LOADER", payload: false });

        return true;
    }
    catch (error) {
        if (error.response && error.response.data) {
            dispatch({ type: "SET_LOADER", payload: false });

            toastError(error.response.data.message || error.response.data.error, toast);
            return false;

        }
    }
}

export const searchAdmin  = (searchText, toast) => async (dispatch)=> {
    try{
        dispatch({type: "DISABLE_PAGE_BTN", payload : {}});
        dispatch({ type: "SET_LOADER", payload: true });
        const { data } = await axiosinstance.get(`/admin/superuser/list?page=${0}&limit=${10}&search=${searchText}`);
        dispatch({type: "SET_ADMINS", payload :{rows : data.data, count : data.count , isfirstpage : false , islastpage: false}});
        dispatch({ type: "SET_LOADER", payload: false });
        return true;

    }
    catch(error){
            dispatch({ type: "SET_LOADER", payload: false });
            toastError(error.response.data.message || error.response.data.error, toast);
            return false;
    }
}

export const signUpAdmin  = (formdata,token, toast)=> async (dispatch)=>{
    try{
        dispatch({type :"SET_LOADER", payload : true });
        const {data} = await axiosinstance.post(`/admin/setup-account/${token}`,formdata);
        dispatch({ type : "SET_LOADER", payload : false});
        toastsuccess("Sign up Successfully", toast);
        return true;
    }
    catch(error){
        dispatch({ type: "SET_LOADER", payload: false });
        toastError(error.response.data.message || error.response.data.error, toast);
        return false;
    }
}
const initialstate = {
    totalSale :0,
    monthlySale : {xaxies:[] , yaxies: [], domain : 0  , premiumdomain : 0, total : 0},
    yearlySale: {xaxies:[] , yaxies: [], domain : 0  , premiumdomain : 0, total: 0, uptolastyearssale: 0},
    daytodaySale :{xaxies:[] , yaxies: [], domain : 0  , premiumdomain : 0, total : 0} 
}

export const salereportReducer = (state = initialstate , action)=>{
    const { type , payload}  = action;
    switch(type){
        case "SET_TOTAL_SALE": 
            return { ...state , totalSale :  payload};
        case "SET_MONTHLY_SALE_REPORT" :
            return { ...state ,monthlySale : payload }
        case "SET_YEARLY_SALE_REPORT":
             return  { ...state , yearlySale: payload};
        case "SET_DAY_TO_DAY_SALE_REPORT": 
            return {...state , daytodaySale : payload};
        default :
            return state
    }
}

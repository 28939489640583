let init_state  = {
    users : [],
    isfirstpage : false,
    islastpage : false,
    count : 0
}
export const userReducer  = (state = init_state , action )=>{
    const {type , payload}  = action;
    switch(type){
        case "SET_USERS":
            return {...state , users : payload.users , isfirstpage : payload.isfirstpage, islastpage : payload.islastpage};
        default :
            return state; 
    }
}
import {
    Box,
    Flex,
    Grid,
    Text,
    FormControl,
    Input,
    Modal,
    Select,
    Avatar,
    Wrap,
    WrapItem,
    useDisclosure,
    Spacer,
    Button,
    ModalContent,
    ModalCloseButton,
    Center,
    Stat,
    StatNumber,
    StatLabel,
    ModalBody,
    InputGroup,
    InputLeftElement,
    ModalHeader,
    ModalOverlay,
    ModalFooter,
    FormLabel,
    Tooltip,
    useColorModeValue,
    SimpleGrid
} from "@chakra-ui/react";
import React, { useState, useEffect } from 'react';
import Card from "components/Card/Card.js";
import LineChart from 'components/Charts/LineChart';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import CardBody from "components/Card/CardBody.js";
import {useToast} from '@chakra-ui/toast';
import {getCurruntMonthUserReports , getMonthWiseReport, getYearlyUserReport } from '../../store/actions/user-report'
export default function Reports(props) {
    const textColor = useColorModeValue("black", "white");
    const today = moment().format("YYYY-MM-DD");
    const toast  = useToast();
    const now  = new Date();
    const dispatch  = useDispatch();
    const userreports = useSelector( state => state.userreportReducer);
    const [graphdata, setgraphdata] = useState({ start:today , end:today , filetype: "xls", ischanged: false });
    const loader = useSelector(state => state.loaderReducer.loader);
    const fileloader = useSelector(state => state.fileloaderReducer.loader);
    
    const changegraphdata = () => {

    }
    const handeldownload = () => {

    }
    useEffect(()=>{
         dispatch(getCurruntMonthUserReports({startdate : 1, enddate :  now.getDate()},toast ));
         dispatch(getMonthWiseReport({startmonth : 1 , endmonth :  now.getMonth()+1},toast ));;
         dispatch(getYearlyUserReport({startyear : 2021,  endyear: now.getFullYear()},toast ));
    }, [])
    
    return (
     
        <Flex color={textColor} flexDirection="column" pt={{ base: "120px", md: "75px" }}>
            <Grid templateColumns={{ sm: "1fr", lg: "1fr" }}
                templateRows={{ sm: "repeat(2, 1fr)", lg: "1fr" }}
                gap="24px"
                mb={{ lg: "26px" }}
                mt={{ lg: "26px", base: "20px" }}>
                <Card>
                    <Text
                        fontSize="3xl"
                        color={textColor}
                        fontWeight="bold"
                    >
                        User Reports 
                    </Text>
                    
                </Card>
                
                <Card>
                    <Text fontWeight={"bold"}>Currunt Month Users  </Text>
                    
                    
                    <SimpleGrid columns={{ sm: 1, md: 2, xl: 2 }} spacing="24px">
                    
                        <Card>
                            <CardBody>
                            <LineChart  xaxies  = {userreports.daytodayUser.xaxies} yaxies={userreports.daytodayUser.yaxies} title ={"Currunt Month User Report"} width = "850"/>
                            </CardBody>
                        </Card>
                    </SimpleGrid>
                </Card>




                <Card>
                    <Text fontWeight={"bold"}>Monthly Users  </Text>
                    
                    
                    <SimpleGrid columns={{ sm: 1, md: 2, xl: 2 }} spacing="24px">
                    
                        <Card>
                            <CardBody>
                            <LineChart  xaxies  = {userreports.monthlyUser.xaxies} yaxies={userreports.monthlyUser.yaxies} title ={"Currunt Year Monthly User Report"} width = "850"/>
                            </CardBody>
                        </Card>
                    </SimpleGrid>
                </Card>





                <Card>
                    <Text fontWeight={"bold"}>Yearly  Users Report </Text>
                    
                    <SimpleGrid columns={{ sm: 1, md: 2, xl: 2 }} spacing="24px">                   
                        <Card>
                            <CardBody>
                            <LineChart  xaxies  = {userreports.yearlyUser.xaxies} yaxies={userreports.yearlyUser.yaxies} title ={"Yearly User Report"} width = "850"/>
                            </CardBody>
                        </Card>
                    </SimpleGrid>
                </Card>
                
            </Grid>
        </Flex>
    )
}
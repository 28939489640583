import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch, Redirect, BrowserRouter } from "react-router-dom";
import store from './store/index';

import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <ToastContainer />
      <Switch>
        <Route path={`/auth`} component={AuthLayout} />
        <Route path={`/admin`} component={AdminLayout} />
        <Redirect from={`/`} to="/admin/dashboard" />
      </Switch>
    </Provider>
  </BrowserRouter>
  ,
  document.getElementById("root")

);
let  initialstate = {
    rows: [],
    page: 1,
    count: 0,
    islastpage: true,
    isfirstpage: true
}


function getafterDeleterows(rows, id) {
    return rows.filter(row => row._id != id);
}

export const adminReducer = (state = initialstate, action) => {
    const { type, payload } = action;
    switch (type) {
        case "SET_ADMINS":
            return { ...state, rows: payload.rows, count: payload.count, page: payload.page, islastpage: payload.islastpage, isfirstpage: payload.isfirstpage };
        case "ADD_ADMIN":
            return { ...state, rows: [...state.rows, payload] };
        case "DISABLE_PAGE_BTN":
            return {...state , isfirstpage: true , islastpage : true};
        case "DELETE_ADMIN":
            return { ...state, rows: getafterDeleterows(state.rows, payload.id) };
        default:
            return state;
    }
}

export const formatCurruntMonthly =(data)=>{
    
}

export const isEmailValid = email => {
    const emailRegularExpression = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
    return emailRegularExpression.test(email);  
}

export const isPasswordValid = password => {
    // 8 to 16 chars, atleast 1 number & 1 special character
    const passwordRegularExpression = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/;
    return passwordRegularExpression.test(password);
}



/*****************************************   USER REPORT *******************************/
import axiosinstance from '../../config/axios';
import { toastsuccess, toastError } from '../../handler/index';
import {updatelocalUser, changelocalImage} from '../../config/index';
import { formatEnterpriseDaytodayData, formatEnterpriseYearlyData , formatEnterpriseMonthlydata} from '../../helper/enterprise-helper';

export const getCurruntMonthEnterpriseReports = (formdata , toast)=> async (dispatch)=>{
    try{
      
        dispatch({ type : "SET_LOADER", payload : true});
        const {data}  = await axiosinstance.get(`/admin/enterprise-report/current-month`);
        
        dispatch({ type: "SET_DAY_TO_DAY_ENTERPRISE_REPORT", payload: formatEnterpriseDaytodayData(data.result) });
        dispatch({ type: "SET_LOADER" , payload: false});

    }
    catch(error){
        
        if (error.response && error.response.data) {
            dispatch({ type: "SET_LOADER", payload: false });
            toastError(error.response.data.message, toast);
            return false;
        }
    }
}

export const  getYearlyEnterpriseReport  = (formdata , toast)=> async  (dispatch)=>{
    try{
        dispatch({ type : "SET_LOADER", payload : true});
        const {data } = await axiosinstance.get(`/admin/enterprise-report/yearly?startyear=${formdata.startyear}&endyear=${formdata.endyear}`);
        dispatch({ type : "SET_YEARLY_ENTERPRISE_REPORT",  payload: formatEnterpriseYearlyData(data.result)});
        dispatch({type :"SET_LOADER" , payload : false});
    }
    catch(error){
        if (error.response && error.response.data) {
            dispatch({ type: "SET_LOADER", payload: false });
            toastError(error.response.data.message, toast);
            return false;
        }
    }
}

export const getMonthWiseReport = (formdata , toast)=> async (dispatch)=>{
    try{
        dispatch({ type : "SET_LOADER", payload : true});
        const {data }  = await axiosinstance.get(`/admin/enterprise-report/monthly?startmonth=${formdata.startmonth}&endmonth=${formdata.endmonth}`);   
        dispatch({  type : "SET_MONTHLY_ENTERPRISE_REPORT", payload : formatEnterpriseMonthlydata(data.result)});
        dispatch({type :"SET_LOADER" , payload : false});
    }
    catch(error){
        if (error.response && error.response.data) {
            dispatch({ type: "SET_LOADER", payload: false });
            toastError(error.response.data.message, toast);
            return false;
        }
    }
} 

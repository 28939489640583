

/*****************************************   USER REPORT *******************************/
import axiosinstance from '../../config/axios';
import { toastsuccess, toastError } from '../../handler/index';
import {updatelocalUser, changelocalImage} from '../../config/index';
import { formatUserDaytodayData, formatUserYearlyData , formatUserMonthlydata} from '../../helper/user-helper';

export const getCurruntMonthUserReports = (formdata , toast)=> async (dispatch)=>{
    try{
      
        dispatch({ type : "SET_LOADER", payload : true});
        const {data}  = await axiosinstance.get(`/admin/user-report/current-month`);
        
        dispatch({ type: "SET_DAY_TO_DAY_USER_REPORT", payload: formatUserDaytodayData(data.result) });
        dispatch({ type: "SET_LOADER" , payload: false});

    }
    catch(error){
        
        if (error.response && error.response.data) {
            dispatch({ type: "SET_LOADER", payload: false });
            toastError(error.response.data.message, toast);
            return false;
        }
    }
}

export const  getYearlyUserReport  = (formdata , toast)=> async  (dispatch)=>{
    try{
        dispatch({ type : "SET_LOADER", payload : true});
        const {data } = await axiosinstance.get(`/admin/user-report/yearly?startyear=${formdata.startyear}&endyear=${formdata.endyear}`);
        dispatch({ type : "SET_YEARLY_USER_REPORT",  payload :formatUserYearlyData(data.result)});
        dispatch({type :"SET_LOADER" , payload : false});
    }
    catch(error){
        if (error.response && error.response.data) {
            dispatch({ type: "SET_LOADER", payload: false });
            toastError(error.response.data.message, toast);
            return false;
        }
    }
}

export const getMonthWiseReport = (formdata , toast)=> async (dispatch)=>{
    try{
        dispatch({ type : "SET_LOADER", payload : true});
        const {data }  = await axiosinstance.get(`/users/monthly?startmonth=${formdata.startmonth}&endmonth=${formdata.endmonth}`);   
        dispatch({  type : "SET_MONTHLY_USER_REPORT", payload : formatUserMonthlydata(data.result)});
        dispatch({type :"SET_LOADER" , payload : false});
    }
    catch(error){
        if (error.response && error.response.data) {
            dispatch({ type: "SET_LOADER", payload: false });
            toastError(error.response.data.message, toast);
            return false;
        }
    }
} 

export const getActiveUserAvgCount = (formdata , toast)=> async (dispatch)=> {
    try {
        dispatch({ type : "SET_LOADER", payload: true});
        const {data }  = await axiosinstance.get(`/admin/active-user-report`);   
        dispatch({  type : "SET_ACTIVE_USER_AVG_COUNT", payload: data.result});
        dispatch({type :"SET_LOADER" , payload: false});
    }
    catch(error){
        if (error.response && error.response.data) {
            dispatch({ type: "SET_LOADER", payload: false });
            toastError(error.response.data.message, toast);
            return false;
        }
    }
}

export const getActiveUserCount = (data , toast)=> async (dispatch)=> {
    try {
        dispatch({ type : "SET_LOADER", payload: true});
        let queryString = '';
        if(data && data.start){
            queryString = `&start=${data.start}&end=${data.end}`;
        }
        const {data: {result: {counts: dailyCounts, days}}}  = await axiosinstance.get(`/admin/analytics-stats?frequency=daily&entity=activeUsers${queryString}`);   
        const {data: {result: {counts: weeklyCounts}}}  = await axiosinstance.get(`/admin/analytics-stats?frequency=weekly&entity=activeUsers${queryString}`);   
        const {data: {result: monthlyCounts}}  = await axiosinstance.get(`/admin/analytics-stats?frequency=monthly&entity=activeUsers${queryString}`);   
        dispatch({  type : "SET_ACTIVE_USER_COUNTS", payload: { dailyCounts, weeklyCounts, days, monthlyCounts }});
        dispatch({type :"SET_LOADER" , payload: false});
    }
    catch(error){
        if (error.response && error.response.data) {
            dispatch({ type: "SET_LOADER", payload: false });
            toastError(error.response.data.message, toast);
            return false;
        }
    }
} 

import {
    Box,
    Flex,
    Grid,
    Text,
    FormControl,
    FormErrorMessage,
    Input,
    Modal,
    Select,
    Avatar,
    Wrap,
    WrapItem,
    useDisclosure,
    Spacer,
    Button,
    ModalContent,
    ModalCloseButton,
    ModalBody,
    InputGroup,
    InputLeftElement,
    ModalHeader,
    ModalOverlay,
    ModalFooter,
    FormLabel,
    Tooltip,
    Center,
    useColorModeValue,
} from "@chakra-ui/react";
// import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Search, Edit } from "@material-ui/icons";
// import { Link } from 'react-router-dom';
import { Table, Thead, Tbody, Tr, Th, Td, chakra } from '@chakra-ui/react'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import Card from "components/Card/Card.js";
import DownloadButton from "views/Common/DownloadButton";
import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useToast } from '@chakra-ui/toast';
import moment from 'moment';
import { getEnterprisePlanPagination, getEnterprisePlans, searchEnterprisePlan, downloadEnterprisePlans,
getEnterprisePlanDetails, updateEnterprisePlan } from '../../store/actions/billing';


export default function EnterprisePlans(props) {
    const toast = useToast();
    const loader = useSelector(state => state.loaderReducer.loader);
    const [curid, setcurid] = useState(null);
    const [isOpen, setOpen] = useState(false);
    // const [searchText, setSearchText] = useState("");
    const [inputData, setInputData] = useState({});
    const [errors, setErrors] = useState({});
    const dispatch = useDispatch();
    const today = moment().format("YYYY-MM-DD");
    const start = moment().subtract(1, "month").format("YYYY-MM-DD");

    const [pagedata, setpagedata] = useState({
        start: start, end: today, filetype: "csv", ischanged: false
    })
    const textColor = useColorModeValue("black", "white");
    const enterprisePlandata = useSelector(state => state.billingReducer);
    const [page, setpage] = useState(1);
    const fileloader = useSelector(state => state.fileloaderReducer.loader);
    const formFields = [
        // "name",
        // "price",
        "expiryDate",
        "userLimit",
        "status"
    ];
    // change page data 
    function changepagedata(e) {
        const name = e.target.name;
        setpagedata({ ...pagedata, [e.target.name]: e.target.value, ischanged: true });
        if (name == "start" || name == "end") {
            dispatch(getEnterprisePlanPagination({ page: page, limit: 10, start: pagedata.start, end: pagedata.end,
                 [e.target.name]: e.target.value }, toast));
            // dispatch(searchEnterprisePlan({page: page, limit: 10, searchText }, toast));
        }


    }
    function handeldownload(e) {
        dispatch(downloadEnterprisePlans({ ...pagedata }, toast));
    }

    // get next page
    function getnextpage() {
        dispatch(getEnterprisePlanPagination({ page: page + 1, limit: 10, start: pagedata.start, end: pagedata.end}, toast));
        setpage(page + 1);
    }

    // get previous page
    function getpreviouspage() {
        dispatch(getEnterprisePlanPagination({ page: page - 1, limit: 10, start: pagedata.start, end: pagedata.end}, toast));
        setpage(page - 1);
    }

    // change handler 
    async function changeHandler(e) {
        const searchText = e.target.value;
        // setSearchText(searchText);
        // if (searchText == "") {
        //     // get first page enterprisePlans
        //     dispatch(getEnterprisePlanPagination({ page: page, limit: 10, start: pagedata.start, end: pagedata.end}, toast));
        // } else {
            // search enterprisePlans
            dispatch(searchEnterprisePlan({page: page, limit: 10, start: pagedata.start, end: pagedata.end, searchText }, toast));
        // }
    }

    async function handelOpenEditModel(id) {
        const plan = await dispatch(getEnterprisePlanDetails(id, toast));
        setOpen(true);
        console.log('plan',plan);
        setInputData(plan);
    }

    function handelCloseEditModel() {
        setOpen(false);
    }

    function debounce(fn, d) {
        let timer;
        return function (...args) {
            const context = this;
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                fn.apply(context, args)
            }, d)
        }
    }

    const searchmyEnterprisePlan = useCallback(debounce(changeHandler, 500), []);

    useEffect(() => {
        dispatch(getEnterprisePlanPagination({ page: page, limit: 10 }, toast));
    }, [])

    // edit plan
    const handleSubmit = async () => {
        const done = await dispatch(updateEnterprisePlan(inputData, toast));
        if (done) {
            handelCloseEditModel();
            dispatch(getEnterprisePlanPagination({ page: page, limit: 10  }, toast));
        }
    }

    function changeInputData(e) {
        const {name, value} = e.target;
        setInputData({...inputData, [name]: value});
        // validate - text
        // add or remove error
        const error = getError(name, value);
        setErrors({...errors, [name]: error});
    }

    function getError(name, value="") {
        let error = '';
        if(!value)
            error = "is required!";
        return error;
    }

    return (
        <Flex flexDirection="column" color={textColor} pt={{ base: "120px", md: "75px" }}>
            <Grid
                templateColumns={{ sm: "1fr", lg: "1fr" }}
                templateRows={{ sm: "repeat(2, 1fr)", lg: "1fr" }}
                gap="24px"
                mb={{ lg: "26px" }}
                mt={{ lg: "26px", base: "20px" }}
            >
                <Card>
                    <Modal isOpen={isOpen} color={textColor} onClose={handelCloseEditModel}>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>Edit Plan</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                {formFields.map(f => {
                                    const title = f.substring(0,1).toUpperCase() + f.split(/(?=[A-Z])/).join(" ").substring(1);
                                    const errTitle = title;
                                    let type = "text";
                                    let value = inputData[f] || "";
                                    let isDisabled = f === "price" ? true : false;
                                    if(f === "expiryDate"){
                                        // date
                                       type = "date";
                                       value = moment(value).format("yyyy-MM-DD");
                                    } else if(f === "userLimit"){
                                        type = "number";
                                    }
                                    return (
                                    <FormControl isRequired isInvalid={errors[f]} key={f} mb="15px">
                                        <FormLabel htmlFor={f} ms="4px" fontSize="sm" fontWeight="normal" color={"black"}>
                                            {title}
                                        </FormLabel>
                                        {f === "status" ? (
                                            <Select placeholder='Select Status' name={f} onChange={changeInputData}>
                                                <option value="active" selected={value === "active"}>active</option>
                                                <option value="inactive" selected={value === "inactive"}>inactive</option>
                                            </Select>
                                        ) :
                                        (<Input id={f} name={f} value={value} onChange={changeInputData} 
                                            type={type}
                                            isDisabled={isDisabled}
                                            borderRadius="15px"
                                            fontSize="sm"
                                            size="lg"
                                            bg="white"
                                            focusBorderColor='orange.100'
                                        />)}
                                        {errors[f] ? (<FormErrorMessage mt="5px">{`${errTitle} ${errors[f]}`}</FormErrorMessage>) : null}
                                    </FormControl>
                                )})}
                            </ModalBody>

                            <ModalFooter>
                                <Button
                                    fontSize="15px"
                                    w="100%"
                                    h="45"
                                    mt={"20px"}
                                    mb="20px"
                                    mr="20px"
                                    varient="outline"
                                    border={"1px solid orage"}
                                    onClick={handelCloseEditModel}>
                                    Close
                                </Button>
                                <Button
                                    isLoading={loader}
                                    loadingText='Deleting...'
                                    type="submit"
                                    fontSize="15px"
                                    bg="#f26400"
                                    w="100%"
                                    h="45"
                                    mb="20px"
                                    color="white"
                                    onClick={handleSubmit}
                                    mt="20px"
                                    _hover={{
                                        bg: "orange",
                                    }}
                                    _active={{
                                        bg: "orange.200",
                                    }}
                                >
                                    Save
                                </Button>

                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                    <Text
                        fontSize="3xl"
                        color={textColor}
                        fontWeight="bold"
                        mb="6px"
                    >
                        EnterprisePlan
                    </Text>

                    <Flex>
                        <Box mt="20px" mb="20px">
                            <Flex>
                                <Box>
                                    <FormControl >
                                        <FormLabel htmlFor='start'>From <span>(MM/DD/YYYY)</span></FormLabel>
                                        <Input type="date" name="start"  onChange={changepagedata} value={pagedata.start} w={"90%"} />
                                    </FormControl>
                                </Box>
                                <Box>
                                    <FormControl>
                                        <FormLabel htmlFor='end'>To <span>(MM/DD/YYYY)</span></FormLabel>
                                        <Input type="date" name="end" onChange={changepagedata} value={pagedata.end} w="90%" />
                                    </FormControl>
                                </Box>
                            </Flex>
                        </Box>
                        <Box mt="20px" mb="20px">
                            <Flex>
                                <Box>
                                    <FormControl>
                                        <FormLabel htmlFor='email'>Select file type</FormLabel>
                                        <Select placeholder='Select file type' name="filetype" onChange={changepagedata} w={"80%"}>
                                            <option value="xls">.xls</option>
                                            <option value="csv" selected={true}>.csv</option>
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box>
                                    <DownloadButton fileloader={fileloader} handeldownload={handeldownload} />
                                </Box>
                            </Flex>
                        </Box>
                    </Flex>
                    <Flex>
                        <FormControl w={"80%"} >
                            <FormLabel>Search enterprisePlan</FormLabel>
                            <InputGroup>
                                <InputLeftElement
                                    pointerEvents='none'
                                    mb={"60px"}

                                    children={<Search color='gray.300' />}
                                />
                                <Input type='text' borderRadius="15px"
                                    bg="white"
                                    name="text"
                                    onChange={searchmyEnterprisePlan}
                                    focusBorderColor='orange.100' placeholder='Enter plan name'
                                />
                            </InputGroup>
                        </FormControl>
                    </Flex>
                    <Flex mt="20px" mb="20px">
                        <Table variant='simple' mt={"1"} >
                            <Thead>
                                <Tr style={{ textTransform: "none" }}>
                                    <Th>Enterprise</Th>
                                    <Th>Plan</Th>
                                    <Th>Type</Th>
                                    <Th>Price</Th>
                                    <Th>Status</Th>
                                    <Th>Expiry Date</Th>
                                    <Th>Action</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {(loader == false && enterprisePlandata && (enterprisePlandata.enterprisePlans && (enterprisePlandata.enterprisePlans.length || enterprisePlandata.enterprisePlans.length === 0))) ?
                                    enterprisePlandata.enterprisePlans.map((row, index) => {
                                        const planType = row.type.substring(0,1).toUpperCase() + row.type.substring(1);
                                        let status = row.status === "active" ? "Active" : "Inactive";
                                        if(new Date(row.expiryDate) < new Date()){
                                            status = "Expired";
                                        } else if(!row.subscriptionData && !row.customPaymentData) {
                                            status = "Trial"; // ? FREE plan, Hubspot init stripe subscriptions
                                        }
                                        return (<>
                                            <Tr key={index}>
                                                <Td> {row.enterpriseId ? row.enterpriseId.name : "NA"}</Td>
                                                <Td> {row.planId.name}</Td>
                                                <Td> {planType}</Td>
                                                <Td>$ {row.totalAmount}</Td>
                                                <Td> {status}</Td>
                                                <Td>{ moment(row.expiryDate).format("YYYY-MM-DD")}</Td>
                                                <Td>
                                                    <Box bg='white' style={{paddingLeft: '1rem', fontWeight: 'bold'}}>
                                                        <Tooltip label='Edit Plan' bg='black' style={{ color: "white" }}>
                                                            <Edit onClick={()=>  handelOpenEditModel(row._id)} fontSize="15px"
                                                                style={{ color: "red", cursor: "pointer" }}
                                                            />
                                                        </Tooltip>
                                                    </Box>
                                                </Td>
                                            </Tr>
                                        </>
                                    )}) : <Flex >
                                        <Center>
                                            Loading.....
                                        </Center>
                                    </Flex>}

                            </Tbody>

                        </Table>

                    </Flex>

                    <Flex>
                        <Spacer></Spacer>
                        <Flex>
                            <Box m={"20px"} rounded="md">
                                <Button
                                    fontSize="15px"
                                    bg="#f26400"
                                    w="100%"
                                    h="45"
                                    borderRadius={"5px"}
                                    color="white"
                                    isDisabled={enterprisePlandata.isfirstpage}
                                    onClick={getpreviouspage}
                                    _hover={{
                                        bg: "orange",
                                    }}
                                    _active={{
                                        bg: "orange",
                                    }}
                                    colorScheme='orange' mr={3} >
                                    <ChevronLeftIcon style={{ color: "white" }}></ChevronLeftIcon>

                                </Button>


                            </Box>
                            <Box rounded="md" m={"20px"}>
                                <Button
                                    isDisabled={enterprisePlandata.islastpage}
                                    fontSize="15px"
                                    bg="#f26400"
                                    w="100%"
                                    h="45"
                                    borderRadius={"5px"}
                                    color="white"
                                    onClick={getnextpage}
                                    _hover={{
                                        bg: "orange",
                                    }}
                                    _active={{
                                        bg: "orange",
                                    }}
                                    colorScheme='orange' mr={3} >
                                    <ChevronRightIcon style={{ color: "white" }}  ></ChevronRightIcon>
                                </Button>
                            </Box>
                        </Flex>

                    </Flex>
                </Card>

            </Grid>
        </Flex >

    )
}



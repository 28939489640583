import { mode } from "@chakra-ui/theme-tools";

export const globalStyles = {
  colors: {
    gray: {
      700: "#1f2733",
    },
    orange: {
      100 : "orange",
      200 :"orange",
      300: "#0000FF"
    },
    orange: {
      100: "#FEEBC8",
      200: "#FBD38D",
      300: "#F6AD55"
    }
  },
  styles: {
    global: (props) => ({
      body: {
        // bg: mode("orange.50", "gray.800")(props),
        bg: mode("hsla(240,7%,81%,.15)")(props),
        fontFamily: 'Helvetica, sans-serif'
      },
      html: {
        fontFamily: 'Helvetica, sans-serif'
      }
    }),
  },
};

import axiosinstance from "./axios"

// const history = useHistory();
export const setToken = (token) => {
    localStorage.setItem("token", token);
}

export const removeToken = () => {
    localStorage.setItem("token");
}

export const getToken = () => {
    return localStorage.getItem("token") ? localStorage.getItem("token") : null;
}

export const  getUser  = ()=>{
    return  localStorage.getItem("user")? JSON.parse( localStorage.getItem("user")):  null;
}
export const removeUser  = ()=>{
    localStorage.removeItem("user");

}
export const updatelocalUser =(formdata)=>{
    let user  = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null;
    if(user){
        localStorage.setItem("user", JSON.stringify({...user, first_name : formdata.first_name , last_name : formdata.last_name , email : formdata.email}));
    }
    else{
        return false;
    }
}

export const changelocalImage  = (formdata)=>{
    let user  = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null;
    if(user){
        localStorage.setItem("user", JSON.stringify({...user, image : formdata.image}));
    }
    else{
        return false;
    }
    
}
import {
    Box,
    Flex,
    Grid,
    Text,
    FormControl,
    Input,
    Modal,
    Select,
    Avatar,
    Wrap,
    WrapItem,
    useDisclosure,
    Spacer,
    Button,
    ModalContent,
    ModalCloseButton,
    ModalBody,
    InputGroup,
    InputLeftElement,
    ModalHeader,
    ModalOverlay,
    ModalFooter,
    FormLabel,
    Tooltip,
    Center,
    useColorModeValue,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Search } from "@material-ui/icons";
import { Link } from 'react-router-dom';
import { Table, Thead, Tbody, Tr, Th, Td, chakra } from '@chakra-ui/react'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import Card from "components/Card/Card.js";
import DownloadButton from "views/Common/DownloadButton";
import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useToast } from '@chakra-ui/toast';
import moment from 'moment';
import { getEnterpriseDetail, getEnterprisePagination, getEnterprises, searchEnterprise, downloadEnterprises } from '../../store/actions/enterprise';


export default function Enterprises(props) {
    const toast = useToast();
    const loader = useSelector(state => state.loaderReducer.loader);
    const dispatch = useDispatch();
    const today = moment().format("YYYY-MM-DD");
    const start = moment().subtract(1, "month").format("YYYY-MM-DD");

    const [pagedata, setpagedata] = useState({
        start: start, end: today, filetype: "csv", ischanged: false, filterBy: "all"
    })
    const textColor = useColorModeValue("black", "white");
    const enterprisedata = useSelector(state => state.enterpriseReducer);
    const [page, setpage] = useState(1);
    const fileloader = useSelector(state => state.fileloaderReducer.loader);

    // change page data 
    function changepagedata(e) {
        const name = e.target.name;
        setpagedata({ ...pagedata, [e.target.name]: e.target.value, ischanged: true });
        if (name == "start" || name == "end") {
            dispatch(getEnterprisePagination({
                page: page,
                limit: 10,
                start: pagedata.start,
                end: pagedata.end,
                [e.target.name]: e.target.value
            }, toast));
        }
    }

    function handeldownload(e) {
        dispatch(downloadEnterprises({ ...pagedata }, toast));
    }

    // get next page
    function getnextpage() {
        dispatch(getEnterprisePagination({ page: page + 1, limit: 10, start: pagedata.start, end: pagedata.end}, toast));
        setpage(page + 1);
    }

    // get previous page
    function getpreviouspage() {
        dispatch(getEnterprisePagination({ page: page - 1, limit: 10, start: pagedata.start, end: pagedata.end}, toast));
        setpage(page - 1);
    }

    // change handler 
    async function changeHandler(e) {
        const searchText = e.target.value;
        // if (searchText == "") {
        //     // get first page enterprises
        //     dispatch(getEnterprisePagination({ page: page, limit: 10, start: pagedata.start, end: pagedata.end}, toast));
        // }
        // else {
            // search enterprises
            dispatch(searchEnterprise({page: page, limit: 10, start: pagedata.start, end: pagedata.end, enterpriseName: searchText }, toast));
        // }
    }

    function debounce(fn, d) {
        let timer;
        return function (...args) {
            const context = this;
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                fn.apply(context, args)
            }, d)
        }
    }

    const searchmyEnterprise = useCallback(debounce(changeHandler, 500), []);

    useEffect(() => {
        dispatch(getEnterprisePagination({ page: page, limit: 10, start: pagedata.start, end: pagedata.end }, toast));
    }, [])

    function filterEnterprises({target:  {value}}) {
        setpagedata({...pagedata, filterBy: value});
        dispatch(getEnterprisePagination({page: page, limit: 10, filterBy: value, start: pagedata.start, end: pagedata.end}, toast));
    }

    return (
        <Flex flexDirection="column" color={textColor} pt={{ base: "120px", md: "75px" }}>
            <Grid
                templateColumns={{ sm: "1fr", lg: "1fr" }}
                templateRows={{ sm: "repeat(2, 1fr)", lg: "1fr" }}
                gap="24px"
                mb={{ lg: "26px" }}
                mt={{ lg: "26px", base: "20px" }}
            >
                <Card>
                    <Text
                        fontSize="3xl"
                        color={textColor}
                        fontWeight="bold"
                        mb="6px"
                    >
                        Enterprise
                    </Text>

                    <Flex>
                        <Box mt="20px" mb="20px">
                            <Flex>
                                <Box>
                                    <FormControl >
                                        <FormLabel htmlFor='start'>From <span>(MM/DD/YYYY)</span></FormLabel>
                                        <Input type="date" name="start"  onChange={changepagedata} value={pagedata.start} w={"90%"} />
                                    </FormControl>
                                </Box>
                                <Box>
                                    <FormControl>
                                        <FormLabel htmlFor='end'>To <span>(MM/DD/YYYY)</span></FormLabel>
                                        <Input type="date" name="end" onChange={changepagedata} value={pagedata.end} w="90%" />
                                    </FormControl>
                                </Box>
                            </Flex>
                        </Box>
                        <Box mt="20px" mb="20px">
                            <Flex>
                                <Box>
                                    <FormControl>
                                        <FormLabel htmlFor='email'>Select file type</FormLabel>
                                        <Select placeholder='Select file type' name="filetype" onChange={changepagedata} w={"80%"}>
                                            <option value="xls">.xls</option>
                                            <option value="csv" selected={true}>.csv</option>
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box>
                                    <DownloadButton fileloader={fileloader} handeldownload={handeldownload} />
                                </Box>
                            </Flex>
                        </Box>
                    </Flex>
                    <Flex>
                        <FormControl w={"60%"} >
                            <FormLabel>Search enterprise</FormLabel>
                            <InputGroup>
                                <InputLeftElement
                                    pointerEvents='none'
                                    mb={"60px"}

                                    children={<Search color='gray.300' />}
                                />
                                <Input type='text' borderRadius="15px"
                                    bg="white"
                                    name="text"
                                    onChange={searchmyEnterprise}
                                    focusBorderColor='orange.100' placeholder='Enter enterprise name'
                                />
                            </InputGroup>
                        </FormControl>
                        <FormControl w={"30%"} ml="20px">
                            <FormLabel htmlFor='filter'>filter by</FormLabel>
                            <Select placeholder='filter by plan type' name="filterBy" onChange={filterEnterprises} w={"80%"}>
                                <option value="all" selected={true}>All</option>
                                <option value="free">Free</option>
                                <option value="standard">Standard</option>
                                <option value="enterprise">Enterprise</option>
                            </Select>
                        </FormControl>
                    </Flex>
                    <Flex mt="20px" mb="20px">
                        <Table variant='simple' mt={"1"} >
                            <Thead>
                                <Tr style={{ textTransform: "none" }}>
                                    {/*<Th>Organization Id</Th>*/}
                                    <Th>Enterprise Name</Th>
                                    <Th>User count</Th>
                                    <Th>Plan</Th>
                                    <Th>Type</Th>
                                    <Th>Onboarded Date</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {(loader == false && enterprisedata && (enterprisedata.enterprises && (enterprisedata.enterprises.length || enterprisedata.enterprises.length === 0))) ?
                                    enterprisedata.enterprises.map((row, index) => (
                                        <>
                                            <Tr key={index}>
                                                {/*<Td> {row._id}</Td>*/}
                                                <Td> {row.name}</Td>
                                                <Td style={{ color: "orange", cursor: "pointer" }}>
                                                    {
                                                        row.userCount != 0 ?
                                                        (<Box as={Button} bg='#f26400' ml="1rem" style={{fontWeight: 'bold', color: 'white'}}
                                                        _hover={{
                                                            bg: "orange",
                                                        }}
                                                        _active={{
                                                            bg: "orange",
                                                        }}
                                                        >
                                                            <Link to={`/admin/users/${row._id}`}>
                                                            {row.userCount}
                                                            </Link>
                                                        </Box>)
                                                        : row.userCount
                                                    }
                                                    
                                                </Td>
                                                <Td>{row.planName}</Td>
                                                <Td>{row.planType.substring(0,1).toUpperCase() + row.planType.substring(1)}</Td>
                                                <Td>{ moment(row.createdAt).format("YYYY-MM-DD")}</Td>

                                            </Tr>

                                        </>


                                    )) : <Flex >
                                        <Center>
                                            Loading.....
                                        </Center>
                                    </Flex>}

                            </Tbody>

                        </Table>

                    </Flex>

                    <Flex>
                        <Spacer></Spacer>
                        <Flex>
                            <Box m={"20px"} rounded="md">
                                <Button
                                    fontSize="15px"
                                    bg="#f26400"
                                    w="100%"
                                    h="45"
                                    borderRadius={"5px"}
                                    color="white"
                                    isDisabled={enterprisedata.isfirstpage}
                                    onClick={getpreviouspage}
                                    _hover={{
                                        bg: "orange",
                                    }}
                                    _active={{
                                        bg: "orange",
                                    }}
                                    colorScheme='orange' mr={3} >
                                    <ChevronLeftIcon style={{ color: "white" }}></ChevronLeftIcon>

                                </Button>


                            </Box>
                            <Box rounded="md" m={"20px"}>
                                <Button
                                    isDisabled={enterprisedata.islastpage}
                                    fontSize="15px"
                                    bg="#f26400"
                                    w="100%"
                                    h="45"
                                    borderRadius={"5px"}
                                    color="white"
                                    onClick={getnextpage}
                                    _hover={{
                                        bg: "orange",
                                    }}
                                    _active={{
                                        bg: "orange",
                                    }}
                                    colorScheme='orange' mr={3} >
                                    <ChevronRightIcon style={{ color: "white" }}  ></ChevronRightIcon>
                                </Button>
                            </Box>
                        </Flex>

                    </Flex>
                </Card>

            </Grid>
        </Flex >

    )
}


